import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  useFetch
} from "hooks/fetch"

// Contexts
import { 
  useGlobals
} from "contexts"

import {
  Checkbox,
  CheckboxGroup
} from "elements"

// Util
import {
  ROUTE_API// -> https://demo.gonucleus.io/api 
} from "util/Routes.const"

import "./FilterStack.css"

import { ReactComponent as IconSearch } from "assets/icons/small/search.svg"

// Section icons
import { ReactComponent as IconLocations } from "assets/icons_midi/locations.svg"
import { ReactComponent as IconDemographics } from "assets/icons_midi/demographics.svg"
import { ReactComponent as IconKeywords } from "assets/icons_midi/keywords.svg"
import { ReactComponent as IconCategories } from "assets/icons_midi/categories.svg"
import { ReactComponent as IconIAB } from "assets/icons_midi/iab.svg"
import { ReactComponent as IconLength } from "assets/icons_midi/length.svg"
import { ReactComponent as IconFormat } from "assets/icons_midi/format.svg"
import { ReactComponent as IconBudget } from "assets/icons_midi/budget.svg"
import { ReactComponent as IconDownload } from "assets/icons_mini/download.svg"

// import { ReactComponent as IconMapAA } from "assets/maps/AA.svg"
// import { ReactComponent as IconMapAF } from "assets/maps/AF.svg"
// import { ReactComponent as IconMapEA } from "assets/maps/EA.svg"
// import { ReactComponent as IconMapEU } from "assets/maps/EU.svg"
// import { ReactComponent as IconMapLA } from "assets/maps/LA.svg"
// import { ReactComponent as IconMapOC } from "assets/maps/OC.svg"
// import { ReactComponent as IconMapWA } from "assets/maps/WA.svg"
// import { ReactComponent as IconMapAN } from "assets/maps/AN.svg"

// Util
import {
  MAP_LOCATIONS,
  MAP_CATEGORIES
} from "util/Mappings.const"

// Sorted locations
const locations_sorted_entries = Object.entries(MAP_LOCATIONS).sort((a, b) => a[1][1].localeCompare(b[1][1]))
let locations_sorted_keys = locations_sorted_entries.map(([key, _]) => key)

// Sorted categories
const categories_sorted_entries = Object.entries(MAP_CATEGORIES).sort((a, b) => a[1][1].localeCompare(b[1][1]))
let categories_sorted_keys = categories_sorted_entries.map(([key, _]) => key)

// TODO: Flags should include default expanding state too (e.g. by using '2' instead of '1')
export const FilterStack = ({flags=[0, 0, 0, 0, 0, 0, 0, 0]}) => {
    const globals = useGlobals()

    // ...
    const locationsSwitch = useRef(false)
    
    // ...
    const [expandedFilters, setExpandedFilters] = useState([])

    // TODO: Input token in global context
    const NUCLEUS_USER_KEY = "nucleus-user"
    const token = localStorage.getItem(NUCLEUS_USER_KEY) ? localStorage.getItem(NUCLEUS_USER_KEY).replace(/\"/g, '') : null

    // Download CSV
    const fetch = useFetch()
    const download = async (what) => {// -> 'Exclude categories' only for the time being (yet easily expandable)
      const categories = (globals.context?.category?.checkboxed || []).toString().replaceAll("checkboxgroup-", "")
      const endpoint = {
        "games": `games?categories=${categories}&detailed=0&csv=1`// -> CSV only: "games?csv=1"
      }[what]
      const _ = await fetch({
        url: `${ROUTE_API/*${publicKey}*/}/reach/list/${endpoint}`,
        token,
        type: what + (categories ? " (excluding" + categories.replace(/,?ca_/gi, " ") + ")" : " (all)")// ALT: (excluding " + (categories.replaceAll("ca_", "").replaceAll(",", " ")) + ")" : " (all)")
      })
    }

    useEffect(() => {
      
      // v2 - Static json file
      const data = globals?.context?.data_json_test?.filters

      // v1 - Legacy
      // const data = globals?.context?.data

      if (data && Object.keys(data).length) {

        // Default view
        setExpandedFilters(expandedFilters.length ? expandedFilters : [1])

        // Available locations (sorted by searchable name)
        const sortedAvailableLocations = new Array(locations_sorted_keys.length)//.fill([])
        for (const item of data.locations) {
          sortedAvailableLocations.splice(locations_sorted_keys.indexOf(item), 1, item)
        }
        locations_sorted_keys = sortedAvailableLocations//.flat()

        // Available categories (sorted by searchable name)
        const sortedAvailableCategories = new Array(categories_sorted_keys.length)//.fill([])
        for (const item of data.categories) {
          sortedAvailableCategories.splice(categories_sorted_keys.indexOf(item), 1, item)
        }
        categories_sorted_keys = sortedAvailableCategories//.flat()
      }
    }, [flags])





    // ...
    // TODO: Every pick group should be stores in its own [sub]namespace (e.g. "locations", "demographics") -> Otherwise there is no easy way to remember all filters at once
    // > For that, perhaps just ask for an special "id='mynamespace'" (or just support an "namespace" prop as part of the <CheckboxGroup/> component)
    const picks = {}
    for (const section of ["regions", "locations", "age", "gender", "cohorts", "category", "time"]) {
      picks[section] = globals.get("checkboxed", section)?.map((pick) => pick.replace("checkboxgroup-", "")) || []
    }

    // Lookup memoization
    const [searchValueLocations, setSearchValueLocations] = useState(Object.keys(MAP_LOCATIONS))
    const [searchValueCategories, setSearchValueCategories] = useState(Object.keys(MAP_CATEGORIES))

    // Batch selections for regions (shared for now)
    const [regionsDropdown, setRegionsDropdown] = useState(null)

    // Batch selections for cohorts (shared for now)
    const [cohortsDropdown, setCohortsDropdown] = useState(null)

    // ---
    // Default choices
    // TODO: Put this logic inside the CheckboxGroup component instead
    const clickCohorts = useRef(null)
    const clickLength = useRef(null)
    useEffect(() => {

      // Demographics
      // Unfold the dropdown (optional) -> NOTE: Clicking on the child table (i.e. no 'parentElement') triggers the dropdown to unfold too
      // clickCohorts.current.parentElement.click()// ALT: new MouseEvent("click", {view: window, bubbles: true, cancelable: true, buttons: 1})
      // Tick all the checkboxes
      for (const cohorts of clickCohorts.current.children) {
        cohorts.firstElementChild.firstElementChild.click()
      }

      // Campaign length
      // Tick default radio button
      clickLength.current.children[2].firstElementChild.click()

    }, [])
    // ---

    return (
      <div className="filterstack">
        <div className={`filterstack__header ${!flags[0]/* TODO: Get this of this fast yet ugly workaround */ ? "filterstack__header--dim" : ""}`}>
          <span className="filterstack__header__label text-xxl-b">Select your preferred audience</span>
          <span className={`${expandedFilters.length ? "filterstack__header__switcher--flip" : ""} filterstack__header__switcher text-m`} onClick={() => setExpandedFilters(expandedFilters.length ? [] : [1, 2, 3, 4, 5])}>{expandedFilters.length ? "Collapse all" : "Expand all"}</span>
        </div>

        {/* Locations */}
        <div className={`filterstack__box ${!flags[1/* See other subcomponents to know why*/ - 1] ? "filterstack__box--disable" : ""} ${expandedFilters.includes(1) ? "" : "filterstack__box--hide"}`}>
          <div className={`filterstack__box__dropdown filterstack__box__dropdown-a`}// TODO: Unify class naming & styles '*wn-a'/'*wn-b' w/ 'wn*' instead
            onClick={() => {
              setRegionsDropdown(!regionsDropdown)
            }}
          >
            <span className="filterstack__box__dropdown-a__label text-s">Region selection</span>
            <div className={"filterstack__box__dropdown-a__arrow-" + (regionsDropdown && expandedFilters.includes(1) ? "up" : "dn")}></div>
            <div className={"filterstack__box__dropdown-a__info text-s"} 
              onClick={(event) => {// -> TIP: Just re-render to simulate a clicked 'off' state (same way switching sections forgets the current state)
                event.stopPropagation()
                setSearchValueLocations((locationsSwitch.current ? locations_sorted_keys : (globals.get("locations")?.checkboxed/* TODO: The 'checkboxed' item ~should be the one returned by default (flags otherwise) */ || [])).map((x) => x.replace("checkboxgroup-", "")))
                locationsSwitch.current = !locationsSwitch.current
              }}>
              {/* <span>{globals.context?.locations?.checkboxed?.length || 0} locations</span> */}
              <div className="filterstack__box__dropdown-a__info__checkboxmini-wrapper">
                <div className="text-xxs">Selected only</div>
                <Checkbox
                  state={locationsSwitch.current ? ((globals.context?.locations?.checkboxed?.length === searchValueLocations.length) ? 2/* on:full */ : 1/* on:half */) : 0/* off */}
                />
              </div>
            </div>

            {/* TODO: Just pass the relevant namespace and the 'grouping code [or "wa_*"-like item prefix if CheckboxGroup re-routing finally supported]' (see 'Mappings.const.js' for further details) */}
            <div className={`filterstack__box__dropdown-a__table text-s`}>
              {[
                {"map_af": ["ao", "bf", "bi", "bj", "bw", "cd", "cf", "cg", "cm", "dj", "dz", "eh", "er", "et", "ga", "gh", "gm", "gn", "gq", "gw", "ke", "km", "lk", "ls", "mg", "ml", "mr", "mu", "mw", "mz", "na", "ne", "ng", "rw", "sc", "sd", "sl", "sn", "so", "ss", "st", "sz", "td", "tg", "tn", "tz", "ug", "za", "zm", "zw"]},
                {"map_ea": ["bd", "bt", "cc", "cn", "hk", "id", "in", "jp", "kh", "kp", "kr", "kz", "la", "mm", "mn", "mo", "mp", "mv", "my", "np", "ph", "pw", "ru", "sg", "th", "tm", "tw", "vn"]},
                {"map_eu": ["ad", "al", "at", "ax", "ba", "be", "bg", "by", "ch", "cy", "cz", "de", "dk", "ee", "es", "fi", "fo", "fr", "gb", "gg", "gi", "gl", "gr", "hr", "hu", "ie", "im", "io", "is", "it", "je", "li", "lt", "lu", "lv", "ma", "mc", "md", "me", "mk", "mt", "nl", "no", "pl", "pt", "ro", "rs", "se", "si", "sk", "sm", "tr", "ua"]},
                {"map_la": ["ag", "ai", "ar", "aw", "bb", "bl", "bo", "bq", "br", "bs", "bz", "cl", "co", "cr", "cu", "cw", "dm", "do", "ec", "fk", "gd", "gt", "gy", "hn", "ht", "jm", "kn", "lc", "mq", "mx", "ni", "pa", "pe", "pn", "pr", "py", "sr", "sv", "sx", "tc", "tt", "uy", "vc", "ve"]},
                {"map_wa": ["ae", "af", "am", "az", "bh", "eg", "ge", "il", "iq", "ir", "jo", "kg", "kw", "lb", "lr", "ly", "om", "pk", "ps", "qa", "sa", "sy", "tj", "uz", "ye"]},
                {"map_aa": ["bm", "ca", "gu", "ky", "ms", "us", "vg", "vi"]},
                {"map_oc": ["as", "au", "bn", "ck", "cx", "fj", "fm", "ki", "mh", "nf", "nr", "nu", "nz", "pf", "pg", "sb", "tk", "tl", "to", "tv", "vu", "ws"]}
              ].map((region_location, index) => {
                const map_id = Object.keys(region_location)[0]
                const map_go = picks["regions"]?.includes(map_id)
                return (
                  <div key={index} className={`filterstack__box__dropdown-a__table__row filterstack__box__dropdown-a__table__row--${map_go ? "select" : ""}`}>
                    <CheckboxGroup label={map_id} checked={/*regionsDropdown !== null ?*/ map_go /*: (map_id === "map_eu")*/} namespace={"regions"} highlight={true} ifttt={{"locations": region_location[map_id]}}/>
                  </div>
                )
              })}
            </div>
          </div>
          
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(1) ? expandedFilters.filter(item => item !== 1) : expandedFilters.concat([1]))}>
            <IconLocations className="filterstack__box__subheader__icon"/>
            <div className="filterstack__box__subheader__label">
              <span>Locations</span>
              <span className={"filterstack__box__subheader__label__subtitle text-s"}>{globals.context?.locations?.checkboxed?.length || 0} selected</span>
            </div>
          </div>
          <div className="filterstack__box__search">
            <IconSearch className="filterstack__box__search_icon"/>
            <input type="text" className="filterstack__box__search__input" placeholder="Search" 
              onInput={(event) => {
                setSearchValueLocations(locations_sorted_entries.map(([key, value]) => !value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? key : []).flat())
              }}// NEXT (2024: ~97%) -> *_sorted.flatMap(([key, value]) => value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? [] : key)
            />
          </div> 
          <div className="filterstack__box__list">
            {locations_sorted_keys.map((locations_code, index) => {
              return ["all_locations", "un", "undefined"].includes(locations_code) || <div key={index} className={`filterstack__box__list__item filterstack__box__list__item--${searchValueLocations.includes(locations_code) ? "" : "hide"} filterstack__box__list__item--${picks["locations"]?.includes(locations_code) ? "select" : ""}`}>
                <CheckboxGroup label={locations_code} checked={picks["locations"]?.includes(locations_code)} highlight={true} namespace={"locations"}/>
              </div>
              }
            )}
          </div>
        </div>

        {/* Demographics */}
        <div className={`filterstack__box ${!flags[2 - 1] ? "filterstack__box--disable" : ""} filterstack__box__flat filterstack__box__flat2 ${expandedFilters.includes(2) ? "" : "filterstack__box__flat--hide"}`}>
        <div /* ref={aaa} */ className={`filterstack__box__dropdown filterstack__box__dropdown-b`} 
          onClick={() => {
            setCohortsDropdown(!cohortsDropdown)
          }}
          >
            <span className="filterstack__box__dropdown-b__label text-s">Cohort selection</span>
            <div className={"filterstack__box__dropdown-b__arrow-" + (cohortsDropdown && expandedFilters.includes(2) ? "up" : "dn")}></div>
            <div ref={clickCohorts} className={`filterstack__box__dropdown-b__table text-s`}>
              {[/* onClick={() => setSelectAll(!selectAll)} */
                {"ilk_all": ["male", "female"]}
              ].map((cohort_gender, index) => {
                const ilk_id = Object.keys(cohort_gender)[0]
                const ilk_go = picks["cohorts"]?.includes(ilk_id)
                return (
                  <div key={index} className={`filterstack__box__dropdown-b__table__row filterstack__box__dropdown-b__table__row--${ilk_go ? "select" : ""}`}>
                    <CheckboxGroup label={ilk_id} checked={ilk_go} namespace={"cohorts"} highlight={true} ifttt={{"gender": cohort_gender[ilk_id]}}/>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(2) ? expandedFilters.filter(item => item !== 2) : expandedFilters.concat([2]))}>
            <IconDemographics className="filterstack__box__subheader__icon"/>
            <div className="filterstack__box__subheader__label">
              <span>Demographics</span>
              <span className={"filterstack__box__subheader__label__subtitle text-s"}>{globals.context?.gender?.checkboxed?.length || 0} selected</span>
            </div>
          </div>
          <div className="filterstack__box__flat__picker">
            
            <div className="filterstack__box__flat__picker__rowset filterstack__box__flat__picker__rowset--disable">
              <div className="filterstack__box__flat__picker__rowset__head">
                <div className="text-s">Age range</div>

                {/* TODO:
                - Demographics (among others) need to be remembered [for the current namespace] while switching between sections/views the same way 'locations' are 
                - We need some kind of memoization system for 'select all' (OR we can just provide an special value for the current block called "*")
                */}
              </div>
              <div className="filterstack__box__flat__picker__rowset__body text-xs">
                <CheckboxGroup label={"18-24"} checked={picks["age"]?.includes("18-24")} namespace={"age"}/>
                <CheckboxGroup label={"25-34"} checked={picks["age"]?.includes("25-34")} namespace={"age"}/>
                <CheckboxGroup label={"35-44"} checked={picks["age"]?.includes("35-44")} namespace={"age"}/>
                <CheckboxGroup label={"45-54"} checked={picks["age"]?.includes("45-54")} namespace={"age"}/>
                <CheckboxGroup label={"55-64"} checked={picks["age"]?.includes("55-64")} namespace={"age"}/>
                <CheckboxGroup label={"65+"} checked={picks["age"]?.includes("65+")} namespace={"age"}/>
              </div>
            </div>
            <div className="filterstack__box__flat__picker__divider"></div>
            <div className="filterstack__box__flat__picker__rowset">
              <div className="filterstack__box__flat__picker__rowset__head">
                <div className="text-s">Gender</div>
              </div>
              <div className="filterstack__box__flat__picker__rowset__body text-xs">
                <CheckboxGroup label={"Male"} checked={picks["gender"]?.includes("male")} namespace={"gender"}/>
                <CheckboxGroup label={"Female"} checked={picks["gender"]?.includes("female")} namespace={"gender"}/>
                <CheckboxGroup label={"Other"} checked={picks["gender"]?.includes("other")} namespace={"gender"} active={false}/>
              </div>
            </div>
          </div>
        </div>

        {/* Campaign length */}
        <div className={`filterstack__box ${!flags[3 - 1] ? "filterstack__box--disable" : ""} filterstack__box__flat filterstack__box__flat1 ${expandedFilters.includes(3) ? "" : "filterstack__box__flat--hide"}`}>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(3) ? expandedFilters.filter(item => item !== 3) : expandedFilters.concat([3]))}>
            <IconLength className="filterstack__box__subheader__icon"/>

            <div className="filterstack__box__subheader__label">
              <span>Campaign length</span>
              <span className={"filterstack__box__subheader__label__subtitle text-s"}>{globals.context?.time?.checkboxed?.length || 0} selected</span>
            </div>

          </div>
          <div className="filterstack__box__flat__picker">
            
            <div className="filterstack__box__flat__picker__rowset">
              {/* <div className="filterstack__box__flat__picker__rowset__head">
                <div className="text-s">Age range</div>
              </div> */}
              <div ref={clickLength} className="filterstack__box__flat__picker__rowset__body text-xs">
                <CheckboxGroup label={"One day"} checked={picks["time"] === "one_day"} namespace={"time"} type={"radio"}/>
                <CheckboxGroup label={"One week"} checked={picks["time"] === "one_week"} namespace={"time"} type={"radio"}/>
                <CheckboxGroup label={"One month"} checked={picks["time"] === "one_month"} namespace={"time"} type={"radio"}/>
                <CheckboxGroup label={"One year"} checked={picks["time"] === "one_year"} namespace={"time"} type={"radio"} active={false}/>
              </div>
            </div>
          </div>
        </div>

        {/* Categories */}
        <div className={`filterstack__box ${!flags[4 - 1] ? "filterstack__box--disable" : ""} ${expandedFilters.includes(4) ? "" : "filterstack__box--hide"}`}>
          <div 
          className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(4) ? expandedFilters.filter(item => item !== 4) : expandedFilters.concat([4]))}>

            {/* ALT 1 */}
            <div className={`filterstack__box__subheader__download`} 
              onClick={(event) => {
                event.stopPropagation()
                download("games")
              }}
            >
              <IconDownload className="filterstack__box__subheader__icon"/>
            </div>
            <IconCategories className="filterstack__box__subheader__icon"/>

            <div className="filterstack__box__subheader__label">

              {/* ALT 2 */}
              {/* <div className="filterstack__box__subheader__label__title" onClick={(event) => {
                event.stopPropagation()
                download("games")
              }}>
                <span>Exclude categories</span>
                <IconDownload className="filterstack__box__subheader__label__icon"/>
              </div> */}

              {/* ALT 1 (if ALT 2 not chosen) */}
              <span>Exclude categories</span>

              {/* Shared */}
              <span className={"filterstack__box__subheader__label__subtitle text-s"}>{globals.context?.category?.checkboxed?.length || 0} selected</span>
            </div>

          </div>
          <div className="filterstack__box__search">
            <IconSearch className="filterstack__box__search_icon"/>
            <input type="text" className="filterstack__box__search__input" placeholder="Search" 
              onInput={(event) => setSearchValueCategories(categories_sorted_entries.map(([key, value]) => !value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? key : []).flat())}// NEXT (2024: ~97%) -> *_sorted.flatMap(([key, value]) => value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? [] : key)
            />
          </div>
          <div className="filterstack__box__list">
            {categories_sorted_keys.map((category_code, index) => {
              return [/*"all_categories", "undefined",*/ "ca_00", "ca_99"].includes(category_code) || <div key={index} className={`filterstack__box__list__item filterstack__box__list__item--${searchValueCategories.includes(category_code) ? "" : "hide"} filterstack__box__list__item--${(picks["category"]?.includes(category_code)) ? "select" : ""}`}>
                <CheckboxGroup label={category_code} checked={picks["category"]?.includes(category_code)} highlight={true} namespace={"category"}/>
              </div>
              }
            )}
          </div>
        </div>

        {/* Budget & CPM */}
        <div className={`filterstack__box ${!flags[5 - 1] ? "filterstack__box--disable" : ""} filterstack__box filterstack__box__flat filterstack__box__flat2 ${expandedFilters.includes(5) ? "" : "filterstack__box__flat--hide"}`}>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(4) ? expandedFilters.filter(item => item !== 5) : expandedFilters.concat([5]))}>
            <IconBudget className="filterstack__box__subheader__icon"/>
            <span className="filterstack__box__subheader__label">Budget & CPM</span>
          </div>
          <div className="filterstack__box__flat__picker">
            
            <div className="filterstack__box__flat__picker__rowset">
              <div className="filterstack__box__flat__picker__rowset__head">
                <div className="text-s">Budget</div>
              </div>
              <div className="filterstack__box__flat__picker__rowset__body text-xs">
                <input className="filterstack__box__flat__search__input" placeholder="$0" 
                  // onInput={(event) => setSearchValueLocations(locations_keys.filter(key => !continentBatchToFilter["cpm"][key].toLowerCase().indexOf(event.target.value)))}
                />
                <div className="filterstack__box__flat__search__label" >{"USD"}</div>
              </div>
            </div>
            <div className="filterstack__box__flat__picker__divider"></div>
            <div className="filterstack__box__flat__picker__rowset">
              <div className="filterstack__box__flat__picker__rowset__head">
                <div className="text-s">CPM</div>
              </div>
              <div className="filterstack__box__flat__picker__rowset__body text-xs">
                <input className="filterstack__box__flat__search__input" placeholder="$0" 
                  // onInput={(event) => setSearchValueLocations(locations_keys.filter(key => !continentBatchToFilter["cpm"][key].toLowerCase().indexOf(event.target.value)))}
                />
                <div className="filterstack__box__flat__search__label" >{"USD"}</div>
              </div>
            </div>

          </div>
        </div>

        {/* Keywords */}
        <div className={`filterstack__box ${!flags[6 - 1] ? "filterstack__box--disable" : ""} ${expandedFilters.includes(6) ? "" : "filterstack__box--hide"}`}>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(6) ? expandedFilters.filter(item => item !== 6) : expandedFilters.concat([6]))}>
            <IconKeywords className="filterstack__box__subheader__icon"/>
            <span className="filterstack__box__subheader__label">Keywords</span>
          </div>
          {/* ... */}
        </div>

        {/* Format */}
        <div className={`filterstack__box ${!flags[7 - 1] ? "filterstack__box--disable" : ""} ${expandedFilters.includes(7) ? "" : "filterstack__box--hide"}`}>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(7) ? expandedFilters.filter(item => item !== 7) : expandedFilters.concat([7]))}>
            <IconFormat className="filterstack__box__subheader__icon"/>
            <span className="filterstack__box__subheader__label">Format</span>
          </div>
          {/* ... */}
        </div>

        {/* IAB */}
        <div className={`filterstack__box ${!flags[8 - 1] ? "filterstack__box--disable" : ""} ${expandedFilters.includes(8) ? "" : "filterstack__box--hide"}`}>
          <div className="filterstack__box__subheader" onClick={() => setExpandedFilters(expandedFilters.includes(8) ? expandedFilters.filter(item => item !== 8) : expandedFilters.concat([8]))}>
            <IconIAB className="filterstack__box__subheader__icon"/>
            <span className="filterstack__box__subheader__label">IAB</span>
          </div>
          {/* ... */}
        </div>

        {/* Offsetting patch */}
        <div className={"filterstack__footer"}></div>
      </div>
  )
}