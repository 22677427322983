import {
  useRef,
  useState,
  useEffect
} from "react"

import {
  useFetch
} from "hooks/fetch"

// Contexts
import { 
  useGlobals
} from "contexts"

// Containers
import { 
  Layout,
  Grid
} from "containers"

// Components
import { 
  FilterStack,
  FilterMonitor,
  TableSort
} from "components"

// Elements
import { 
  Button,
  Breadcrumbs
} from "elements"

// Util
import {
  ROUTE_API// -> https://demo.gonucleus.io/api 
} from "util/Routes.const"

// Calculator-wide styling
import "./Calculator.css"

import { ReactComponent as IconSearch } from "assets/icons/small/search.svg"

// ...
let arrPrecampaigns = []
const fillCampaigns = (precampaigns) => {
  const tempArr = []

  // v2
  for (const precampaign of precampaigns) {
    tempArr.push([
      precampaign["name"], 
      precampaign["id"], 
      precampaign["reach"], 
      precampaign["netreach"], 
      precampaign["length"], 
      precampaign["cpm"], 
      precampaign["actions"]
    ])
  }

  // v1 - Legacy
  // const section = campaigns["campaigns"]
  // for (const id of Object.keys(section)) {
  //   const row = section[id]
  //   tempArr.push([row["campaign"], id, row["reach"], row["netreach"], row["campaignlength"], row["cpm"], row["actions"]])
  // }

  tempArr.unshift(["", "", [0, 0], [0, 0], 0, 0, [0, 0]])// -> Template row for averages (asumes 'TableSort stick={true}')
  return tempArr
}
const removeCampaign = (index) => {
  return arrPrecampaigns.filter(item => arrPrecampaigns.indexOf(item) !== (index))
}

// let foo = true

// Calculator assembly
export const Calculator = ({ready}) => {
    const fetch = useFetch()

    const globals = useGlobals()
    const results = globals?.context?.data?.reach?.results// TODO: Fetch from here [vs from App] (after filtering)

    const step = useRef(results === undefined ? 0 : 1/* Skip 1st [to be 'Campaign type'] view after coming back from other sidebar section if filters already loaded */)
    const next = useRef(true)

    const [rendered, reRender] = useState(0)
    const [loading, setLoading] = useState(false)

    const sequence = ["Set filters", "Get results", "Overview"] //["Reach campaign", "Set filters", "Get results"]// ["Select campaign", "Set filters", "Get results"]// FULL (next): ["Select campaign type", "Select audience", "Choose content", "Define period & format", "Set budget"]

    // TODO: Input token in global context
    const NUCLEUS_USER_KEY = "nucleus-user"
    const token = localStorage.getItem(NUCLEUS_USER_KEY) ? localStorage.getItem(NUCLEUS_USER_KEY).replace(/\"/g, '') : null

    // Fetch API data -> https://adverty-reach-ks8ud.ondigitalocean.app/docs
    /* TODO: 
      - Cache locally (w/ localStorage) until TTL 
    */
    const retrieve = async (what) => {
      const payload = {}
      let success = what === undefined// -> Pass through if unsupported
      setLoading(true)

      // TODO: Shared loop instead
      // 1/2 - Retrieve filters
      if (what === "filters") {

        // Location data
        // --- DEV
        console.log("Request:", `${ROUTE_API/*${publicKey}*/}/reach/list/locations?detailed=0`)
        // --- DEV
        const responseLocations = await fetch({
          url: `${ROUTE_API/*${publicKey}*/}/reach/list/locations?detailed=0`,
          token
        })
        if (responseLocations?.status === "success") {
          payload["locations"] = globals.context.data.reach.locations = responseLocations?.payload?.locations
          success = true
        } 
 
        // Categories data
        // --- DEV
        console.log("Request:", `${ROUTE_API/*${publicKey}*/}/reach/list/categories?detailed=0`)
        // --- DEV
        const responseCategories = await fetch({
          url: `${ROUTE_API/*${publicKey}*/}/reach/list/categories?detailed=0`,
          token
        })
        if (responseCategories?.status === "success") {
          payload["categories"] = globals.context.data.reach.categories = responseCategories?.payload?.categories
          success = success && true
        }
      } 
      else 

      // 2/2 - Retrieve results
      if (what === "results") {

        // Results data -> locations=af,al&gender=male&gamelist=ca_aa,ca_hc,ca_i,ca_r&length=30
        const queryLocations = (globals?.context?.locations?.checkboxed || []).toString()
        const queryGamelist = (globals?.context?.category?.checkboxed || []).toString()
        const queryGender = (globals?.context?.gender?.checkboxed || []).toString()// TODO: Merge defaults w/ FilterStack's
        const queryTime = {
          "checkboxgroup-one_day": 1,
          "checkboxgroup-one_week": 7,
          "checkboxgroup-one_month": 30,
          "checkboxgroup-one_year": 365
        }[(globals?.context?.time?.checkboxed || []).toString()]
        const queryChain = `locations=${queryLocations}&gender=${queryGender}&categories=${queryGamelist}&length=${queryTime}`.replaceAll("checkboxgroup-", "")
        // --- DEV
        console.log("Request:", queryChain)
        // --- DEV

        const responseResults = await fetch({
          url: `${ROUTE_API/*${publicKey}*/}/reach?${queryChain}&detailed=0}`,
          token
        })

        if (responseResults?.status === "success") {
          payload["results"] = globals.context.data.reach.results = responseResults?.payload
          success = true

          // Equivalent to auto-clicking "Get results" after asking 'go back to the first view and come here again' by manually clicking "Refresh results"
          step.current = 2
        }
      } else 

      // 3/3 - Retrieve campaigns
      if (what === "precampaigns") {
        setLoading(false)
          // ...
      }

      return new Promise((resolve, reject) => {
        if (success) {
          setLoading(false)
          resolve(payload)
        } else {
          reject("Error: not all data was retrieved")
        }
      })
    }

    useEffect(() => {

      if (!ready) return// ALT: if (!globals?.context?.data_json_test?.filters) return 

      step.current === 1/* || step.current === 2) // -> To refresh on edit from campaigns table */ && retrieve("results").then((payload) => {
        //step.current += 1
        // --- DEV
        console.log("Response:", payload)
        // --- DEV
      }).catch((error) => {
        // step.current = 0
        console.error(error)
      })

      // TODO: Merge w/ the upper block when ready
      step.current === 3 && !arrPrecampaigns.length && retrieve("precampaigns").then((payload) => {
        //step.current += 1
        // --- DEV
        console.log("Response ???:", payload)
        // --- DEV
      }).catch((error) => {
        console.error(error)
      })
 
      // // Head
      // // TODO: This line isn't needed/desired (not for this mode/table)
      // ["Campaigns",	"", [0, 0], [0, 0], 0, 0, 0],
      
      // // Body
      // ["Find your Greatness",	"1a7847f626a678e71cd569f1", [1000000000, 7200000000], [1000000000, 2500000000], 6, 34, 66666666],
      // ["iDream: Unleash your Imagination",	"2a7847f626a678e71cd569f2", [2000000000, 7200000000], [2000000000, 2500000000], 12, 20, 66666666],
      // ["Just Move: Be Unstoppable",	"3a7847f626a678e71cd569f3", [3000000000, 7200000000], [3000000000, 2500000000], 6, 0, 96666666],
      // ["It's Time Now",	"4a7847f626a678e71cd569f4", [4000000000, 7200000000], [4000000000, 2500000000], 1, 20, 66666666],
      // ["Share Happiness Sip Together",	"5a7847f626a678e71cd569f5", [5000000000, 7200000000], [5000000000, 2500000000], 12, 0, 66666666],
      // ["Prime Day: Shop Smart",	"6a7847f626a678e71cd569f6", [6000000000, 7200000000], [6000000000, 2500000000], 12, 0, 96666666],
      // ["Drive Electric: Future Awaits",	"7a7847f626a678e71cd569f7", [7000000000, 7200000000], [7000000000, 2500000000], 6, 0, 96666666]
      
      // Hardcoded payload example
      // const payload = {
      //   "campaigns": {
      //     "1a7847f626a678e71cd569f1": {
      //       "campaign": "Find your Greatness",
      //       "reach": [1000000000, 7200000000],
      //       "netreach": [1000000000, 2500000000],
      //       "campaignlength": 6,
      //       "cpm": 34,
      //       "actions": [1, 1]
      //     },
      //     "2a7847f626a678e71cd569f2": {
      //       "campaign": "iDream: Unleash your Imagination",
      //       "reach": [2000000000, 7200000000],
      //       "netreach": [2000000000, 2500000000],
      //       "campaignlength": 12,
      //       "cpm": 20,
      //       "actions": [1, 1]
      //     },
      //     "3a7847f626a678e71cd569f3": {
      //       "campaign": "Just Move: Be Unstoppable",
      //       "reach": [3000000000, 7200000000],
      //       "netreach": [3000000000, 2500000000],
      //       "campaignlength": 6,
      //       "cpm": 0,
      //       "actions": [1, 1]
      //     },
      //     "4a7847f626a678e71cd569f4": {
      //       "campaign": "It's Time Now",
      //       "reach": [4000000000, 7200000000],
      //       "netreach": [4000000000, 2500000000],
      //       "campaignlength": 1,
      //       "cpm": 20,
      //       "actions": [1, 1]
      //     },
      //     "5a7847f626a678e71cd569f5": {
      //       "campaign": "Share Happiness Sip Together",
      //       "reach": [5000000000, 7200000000],
      //       "netreach": [5000000000, 2500000000],
      //       "campaignlength": 12,
      //       "cpm": 0,
      //       "actions": [1, 1]
      //     },
      //     "6a7847f626a678e71cd569f6": {
      //       "campaign": "Prime Day: Shop Smart",
      //       "reach": [6000000000, 7200000000],
      //       "netreach": [6000000000, 2500000000],
      //       "campaignlength": 12,
      //       "cpm": 0,
      //       "actions": [1, 1]
      //     },
      //     "7a7847f626a678e71cd569f7": {
      //       "campaign": "Drive Electric: Future Awaits",
      //       "reach": [7000000000, 7200000000],
      //       "netreach": [7000000000, 2500000000],
      //       "campaignlength": 6,
      //       "cpm": 0,
      //       "actions": [1, 1]
      //     }
      //   }
      // }
      // "id": "7a7847f626a678e71cd569f7",
      // "name": "Drive Electric: Future Awaits",
      // "reach": [1700000000, 7200000000],
      // "netreach": [7000000000, 2500000000],
      // "length": 180,
      // "cpm": 0,
      // "actions": [1, 1]


      // NOTE: Instead of global context b/c it's just a mutable local reference
      if (!arrPrecampaigns.length) {
        
        // v2
        arrPrecampaigns = fillCampaigns(globals?.context?.data_json_test?.precampaigns)// TODO: Put this inside retrieved promised response 
        
        // v1
        // arrPrecampaigns = fillCampaigns(payload)// TODO: Put this inside retrieved promised response 

        // console.log("CAMPAIGNS", arrPrecampaigns, globals?.context?.data_json_test?.precampaigns)
      }

    }, [rendered])

    useEffect(() => {
      if (ready) {
        step.current = 1
        setLoading(undefined)// -> Use 'reRender(Date.now())' instead to [auto]get results after ready
      }
    }, [ready]) 

    return (
      <Layout autoscroll={loading} header={{// TODO: To be converted into a element component (same for [pagination/breadcrumbs] content itself)
        "title": <Breadcrumbs step={step.current - 1} sequence={sequence}/>, 
        "search": "breadcrumbs-temp"// TODO: This is a temporary solution (to be removed for a properly unified/stylized header)
      }}>

      {step.current > 2 ? 
      <>
        <div className="_tempDummyHeader">Campaign planning</div>{/* TODO: Header element needed */}
        <div className="calculator-subheader">
          <div className="calculator-subheader__search">
           
            <IconSearch className="calculator-subheader__search_icon"/>
            <input type="text" className="calculator-subheader__search__input text-m" placeholder="Search by name or ID..." 
              onInput={(event) => {
                // setSearchValueLocations(locations_sorted_entries.map(([key, value]) => !value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? key : []).flat())
              }}// NEXT (2024: ~97%) -> *_sorted.flatMap(([key, value]) => value[1].toLowerCase().indexOf(event.target.value.toLowerCase()) ? [] : key)
            />
          </div> 
          <Button text={"Share with Advertiser"} onClick={() => window.location.reload()} disabled={true}/>
          <Button text={"Create campaign"} onClick={() => window.location.reload()}/>
        </div>
      </> : ""}

      {step.current < 3 ? 
        <Grid 
          content={[
              <FilterStack flags={step.current === 0 ? [] : [1, 1, 1, 1, 0, 0, 0, 0]}/>,
              <FilterMonitor sessions={results?.sessions} uniques={results?.uniques} disabled={step.current === 0}/>
          ]}
          template={`
              0 1
              `}
          cellSize={{
            w: 1,
            h: 1
          }}
          flat={true}
        />
        :
        (arrPrecampaigns.length < 2 ? "" : <Grid 
        content={[

          <TableSort  
            data={removeCampaign()}// TODO: No need to call rC twice (see the second one below)

            // TODO: Skip this prop and pass the full JSON payload to the table instead (so we can use its keys as labels, skip the conversion logic, and so on)
            headerLabels={[
              "Campaigns",
              "Campaign ID",
              "Estimated reach",
              "Estimated net reach",
              "Campaign length",
              "CPM",
              "Actions"
            ]}
            stick={true}

            /* TODO: 
              Support for different table layouts (e.g. mode=0 -> common data-viz table)
              > We should do this at the header level (i.e. by customizing cell types)
             */
            mode={1}
            callback={(cell) => {
              if (isNaN(cell)) {
                next.current = false
                step.current -= 1
                reRender(Date.now())
              } else {
                arrPrecampaigns = removeCampaign(cell + 0)
                reRender(Date.now())
              }
            }}
          />
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 0,

          // TODO: Height for the tableSort component should govern 'row height' (vs 'table height') -> We could use a grid-h:0 for this (& then control the table layout from the tableSort component)
          // > UPDATE: We just need to do "number of instances" times "desired row height"
          h: 49/* Header */ + (57/* Body */ * (arrPrecampaigns.length + 0))
        }}
        flat={false}
      />)
      }
      <div className="calculator-paginator">

        {/* Prev */}
        <Button text={loading && !next.current ? "Loading..." : ["Home", "Campaign type", "Refresh results", "Edit results"][step.current]} onClick={
          () => {
            next.current = false
            step.current -= 1
            reRender(Date.now())
          }
        } disabled={step.current < 2 || (loading && next.current)} loading={loading && !next.current}/>

        {/* Next */}
        {/* TODO: Include when campaigns section is ready */}
        {/* <Button text={loading && next.current ? "Loading..." : ["Load filters", "Get results", "Manage campaigns", "Home"][step.current]} onClick={
          () => {
            next.current = true 
            //step.current = 2
            step.current += +(step.current > 1)// -> Because under 1 all the 'next' actions are automated (see 'step.current' instances above)
            reRender(Date.now())
          }
        } disabled={step.current === 3 || (loading && !next.current)} loading={loading && next.current}/> */}
        
        {/* Next */}
        {/* FIXME: Keep this for now */}
        <Button text={loading ? "Loading..." : ["Load filters", "Get results", "Update results"][step.current]} onClick={
          () => {
            step.current = 1// NEXT (w/ overview table): step.current += 1
            reRender(Date.now())
          }
        } disabled={false} loading={loading}/>

      </div>
      </Layout>
)}