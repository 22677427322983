import { 
    useRef
} from "react"

import { 
    Dropdown
} from "elements"

import {
    ROTANA_DEMO_MODE 
} from "util/const"

// import { ReactComponent as MenuIcon } from 'assets/Menu.svg'
import { ReactComponent as NucleusIcon } from 'assets/logo-icon.svg'

// // Legacy
// import dummySearchBar from "assets/_poc/1296x68_search_1_k.png"

// Styling
import "./Header.css"


export const Header = ({ content, callback, mode }) => {
    const sidebarHidden = useRef(true)
    const mutableButton = useRef(null)

    return (
        <div className={`header ${content.search === "breadcrumbs-temp" ? "" : "header--temp-dropdown-filters"}`}>
            <div className='header__icon__wrapper'>
                <NucleusIcon/>
            </div>
            <div className="header__h2__wrapper">
                {
                typeof content.title === "string"
                ? 
                <div className={"xxxx"}>
                    <Dropdown mode={0}/>
                    <Dropdown mode={1}/>
                    {ROTANA_DEMO_MODE ? "" : <Dropdown mode={8}/>/* TODO: pass 'data' prop from campaigns static JSON's collection */}
                    <Dropdown mode={2}/>
                    <Dropdown mode={3}/>
                    <Dropdown mode={4}/>
                    <Dropdown mode={5}/>
                    <Dropdown mode={6}/>
                    <Dropdown mode={7}/>
                    </div> 
                    // ALT 1: <img className="_tempDummyHeaderImage" src={dummySearchBar/*content.search*/}/>
                    // ALT 2: <h2 className='header__content'>{content.title}</h2>
                :
                content.title
                }
            </div>
            <div className={`header__menu__wrapper ${sidebarHidden.current ? "header__menu__wrapper--toggle" : ""}`} onClick={() => {
                mutableButton.current.classList = "burger-animate " + (mutableButton.current.classList.contains("burger-close") ? "burger-menu" : "burger-close")
                callback(sidebarHidden.current = !sidebarHidden.current)
                }}>

                {/* /* TODO: Make a element component out of this block */}
                <div className="burger">
                    <div className={sidebarHidden.current ? "" : "burger-overlay"}></div>
                    <div ref={mutableButton} className="burger-menu"></div>
                </div>
            </div>
        </div>
    )
}

// === Legacy
// export const Header = ({ content, callback }) => {

//     return (
//         <div className="header">
//             <div className="header__h2__wrapper">
//                 {/* <h2 className={`header__content`}>{content.content}</h2>  */}
//                 {dummySearchBar //content.search
//                     ? 
//                     // <img className="_tempDummyHeaderImage" src={dummySearchBar/*content.search*/}/>
//                     <></>
//                     // <h2 className={`header__content header__content__loading`}>Loading...</h2> 
//                     : 
//                     <h2 className={`__header__content`}>{content.content}</h2> 
//                 }
//             </div>
//         </div>
//     )
// }




