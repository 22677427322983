import {
    useState,
    useEffect,
    useMemo
} from "react"

// Elements
import { 
    Counter
} from "elements"

// Sytling
import "./PieArc.css" 

const trace = (angle) => {
    const radians = (angle - 90) * Math.PI / 180.0  
    return {
        x: 50 + (50 * Math.cos(radians)),
        y: 50 + (50 * Math.sin(radians))
    }
}
const numberCommaize = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const PieArc = ({/* pct, val, */ values, percentage/* alt/temp */ }) => {
    // const percentage = useMemo(/* Compute once (unless 'values' changes) */() => {
    //     return Math.round(100 * (values?.[0] / values.reduce((a, b) => a + b, 0))) 
    // }, [values])
    const [coords0, setcoords0] = useState({x: 50, y: 0})
    const [coords1, setcoords1] = useState({x: 50, y: 0})
    const offset1 = 0 //5
    useEffect(() => {
        const factor = 180 / 100/* divisions */
        let startTimestamp = null
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp 
            const progress = Math.min((timestamp - startTimestamp) / 300/* duration */, 1)
            setcoords0(trace(progress * (percentage - (offset1 / 2)) * factor))
            setcoords1(trace(progress * ((100 - percentage) - (offset1 / 2)) * factor))
            if (progress < 1) {
            window.requestAnimationFrame(step)
            } 
        }
        window.requestAnimationFrame(step)
    }, [percentage])

    return (
    <div className={"piearc"} style={{
        "--__piearc": (offset1 + (180 * (percentage / 100))) + "deg"
    }}>

        <div className="piearc__part-wrapper">
            <svg className={`aaaa piearc__part piearc__part-offset`} viewBox="0 0 100 100">     
                <path className={`bbbb piearc__part1b__path`} d={`M 50 -5 50 0 L 50 50`}/>
            </svg>
            <svg className={`piearc__part`} viewBox="0 0 100 100">     
                <path className={`piearc__part1a__path`} d={`M ${coords0.x} ${coords0?.y} A 50 50 0 0 0 50 0 L 50 50 L ${coords0.x} ${coords0?.y}`}/>
            </svg>
            <svg className={`piearc__part piearc__part-offset`} viewBox="0 0 100 100">     
                <path className={`piearc__part1b__path`} d={`M ${coords1.x} ${coords1?.y} A 50 50 0 0 0 50 0 L 50 50 L ${coords1.x} ${coords1?.y}`}/>
            </svg>

        </div> 
        <div className="piearc__part-wrapper">
            <svg className={`piearc__part piearc__part-inner`} viewBox="0 0 100 100">
                <path className="piearc__part2x__path" d={`M ${50 - 0.1} 0 A 50 50 0 1 0 50 0`}/>
            </svg>
        </div>
        <div className="piearc__part-wrapper">
            <svg className={`piearc__part piearc__part-inner`} viewBox="0 0 100 100">
                <path className="piearc__part3a__path" d={`M ${coords0.x} ${coords0?.y} A 50 50 0 0 0 50 0`}/>
            </svg>
            <svg className={`piearc__part piearc__part-inner piearc__part-offset`} viewBox="0 0 100 100">
                <path className="piearc__part3b__path" d={`M ${coords1.x} ${coords1?.y} A 50 50 0 0 0 50 0`}/>
            </svg>
        </div>

        <div className="piearc__part-counter text-xxxl-b">
            {/* <Counter value={pct}/> */}
            <Counter value={percentage}/>
            <div className={"piearc__part-counter__pct"}>%</div>
        </div>

        <div className="piearc__part-row text-s">
            {/* <div></div>
            <div>Ads sent</div>
            <div>{numberCommaize(val)}</div>
            <div></div>
            <div></div>
            <div>Ads requested</div>
            <div>{numberCommaize(Math.round(((100 - pct) * val) / pct))}</div> */}

            {/* <div></div>
            <div>Ads sent</div>
            <div>{numberCommaize(values?.[0])}</div>
            <div></div>
            <div></div>
            <div>Ads requested</div>
            <div>{numberCommaize(values?.[1])}</div> */}

            <div className={"piearc__part-row__item"}>
                <div>
                    <div></div><div>Ads sent</div>
                </div>
                <div>
                <div></div><div>{numberCommaize(values?.[0])}</div>
                </div>
            </div>
            <div></div>
            <div className={"piearc__part-row__item"}>
                <div>
                    <div></div><div>Ads requested</div>
                </div>
                <div>
                    <div></div><div>{numberCommaize(values?.[1])}</div>
                </div>
            </div>

        </div>

    </div>
)}