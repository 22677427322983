import {
  Insert,
  Tick
} from "elements"

import "./PopoverHML.css"
import { ROTANA_DEMO_MODE } from "util/const"
/*
HOW: To be toggled from parent, like this:
  onSomething={(event) => {
      event.SOME_PARENT_TARGET.classList.toggle(`popoverhml--show`)
  }}
*/
export const PopoverHML = ({name, data, flip, tail, kiss=false}) => {
  return (
    <div className={`popoverhml ${kiss ? "popoverhml--kiss" + (ROTANA_DEMO_MODE ? "er" : "") : ""} ${flip ? "popoverhml--flip" : ""} text-xs-b`} style={{
      "--popoverhml-offset": (tail * -1) + "px"
    }}>
      <Insert what={name} heap={kiss ? null : true}/>
      <div className="popoverhml__table text-xxs">

        {/* TODO: Support any kind of data (& get H/M/L label from the data object items) */}
        <div>High</div><div><Tick value={data?.[0]+"%"} tag="1"/></div>
        <div>Medium</div><div><Tick value={data?.[1]+"%"} tag="2"/></div>
        <div>Low</div><div><Tick value={data?.[2]+"%"} tag="3"/></div>
      </div>
    </div>
  )
}