import {
  useState
} from "react"

import {
  Link,
  useLocation,
  useNavigate
} from "react-router-dom"

// Components
import {
  Avatar
} from "components"

// Util
import {
  ROUTE_HOME,
  ROUTE_CAMPAIGNS,
  ROUTE_CAMPAIGNS_01,
  ROUTE_CAMPAIGNS_02,
  ROUTE_CAMPAIGNS_03,
  ROUTE_CAMPAIGNS_04,
  ROUTE_CAMPAIGNS_05,
  ROUTE_CAMPAIGNS_06,
  ROUTE_SUBSCRIPTIONS,
  ROUTE_SUBSCRIPTIONS_01,
  ROUTE_SUBSCRIPTIONS_02,
  ROUTE_SUBSCRIPTIONS_03,
  ROUTE_SUBSCRIPTIONS_04,
  ROUTE_SUBSCRIPTIONS_05,
  ROUTE_SUBSCRIPTIONS_06,
  ROUTE_VIDEO,
  ROUTE_HELP,
  ROUTE_SETTINGS,
  ROUTE_CALCULATOR,
  ROUTE_CONTENT

} from "util/Routes.const"

// Consts
import {
  SHOW_N_ENABLE_ALL,
  ENABLE_ROUTE_HOME,
  ENABLE_ROUTE_SUBSCRIPTIONS,
  ENABLE_ROUTE_VIDEO,
  ENABLE_ROUTE_HELP,
  ENABLE_ROUTE_SETTINGS,
  ENABLE_ROUTE_CALCULATOR,
  ENABLE_ROUTE_CONTENT,
  ROTANA_DEMO_MODE,

  // Subsections
  ENABLE_ROUTE_CAMPAIGNS_OVERVIEW,
  ENABLE_ROUTE_CAMPAIGNS_LOCATION,
  ENABLE_ROUTE_CAMPAIGNS_AUDIENCE,
  ENABLE_ROUTE_CAMPAIGNS_CATEGORY,
  ENABLE_ROUTE_CAMPAIGNS_TIMING,
  ENABLE_ROUTE_CAMPAIGNS_DEVICE,
  ENABLE_ROUTE_CAMPAIGNS_IAB,
  SHOW_ROUTE_CAMPAIGNS_OVERVIEW,
  SHOW_ROUTE_CAMPAIGNS_LOCATION,
  SHOW_ROUTE_CAMPAIGNS_AUDIENCE,
  SHOW_ROUTE_CAMPAIGNS_CATEGORY,
  SHOW_ROUTE_CAMPAIGNS_TIMING,
  SHOW_ROUTE_CAMPAIGNS_DEVICE,
  SHOW_ROUTE_CAMPAIGNS_IAB,
  
} from "util/const"

// Integration-wide styling
import "./Sidebar.css"

// SVG assets
import { ReactComponent as NucleusLogoIcon } from "assets/logo-icon.svg"
import { ReactComponent as HomeIcon } from "assets/icons_mini/home.svg"
import { ReactComponent as SettingsIcon } from "assets/icons_mini/settings.svg"
import { ReactComponent as HelpIcon } from "assets/icons_mini/help.svg"
import { ReactComponent as SubscriptionsIcon } from "assets/icons_mini/subscriptions.svg"
import { ReactComponent as VideoIcon } from "assets/icons_mini/video.svg"
import { ReactComponent as CampaignsIcon } from "assets/icons_mini/campaigns.svg"
import { ReactComponent as CalculatorIcon } from "assets/icons_mini/calculator.svg"

import { ReactComponent as TriwaveIcon } from "assets/icons_mini/triwave.svg"
import { ReactComponent as TargetAudienceIcon } from "assets/icons_mini/target_audience.svg"
import { ReactComponent as GeographicInsightsIcon } from "assets/icons_mini/geographic_insights.svg"
import { ReactComponent as ContentPerformanceIcon } from "assets/icons_mini/content_performance.svg"
import { ReactComponent as InterestCategoriesIcon } from "assets/icons_mini/interest_categories.svg"
import { ReactComponent as TimingIcon } from "assets/icons_mini/timing.svg"
import { ReactComponent as DevicePerformanceIcon } from "assets/icons_mini/device_performance.svg"

// Primary navigation bar
export const Sidebar = ({mobile}) => {
  
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  const pick = (me) => {
    const mode = mobile ? "mobile__top__item" : "desktop"
    return "sidebar-" + mode + "__list__bullet " + (
      pathname === me ? "sidebar-" + mode + "__list__bullet--select" : (
        pathname.includes(me) ? "sidebar-" + mode + "__list__bullet--preselect" : ""))
  }

  const WrapLink = ({ route, shouldWrap, children }) => (
    shouldWrap
      ? (
        <Link to={route}>
          {children}
        </Link>
      ) : children
  )

  const segment_this = pathname?.replace(/[/0-9]+/g, "")

  // Mobile only
  const [expandedRow, setExpandedRow] = useState({
    "campaigns": 1, 
    "subscriptions": 2
  }[segment_this])

  return (
  
    mobile
    ?
    <nav className="sidebar-mobile">
      {/* Top */}
      <div className="sidebar-mobile__top">
        
                                    {ROTANA_DEMO_MODE ? <div className={"sidebar-mobile__top__item__list__bullet__icon--disabled"}>
                                      <HomeIcon className={`sidebar-mobile__top__item__list__bullet__icon`}/>
                                      <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Home</span>
                                    </div> : ""}
                                    {ROTANA_DEMO_MODE ? <div className={"sidebar-mobile__top__item__list__bullet__icon--disabled"}>
                                      <SubscriptionsIcon className={`sidebar-mobile__top__item__list__bullet__icon`}/>
                                      <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Subscriptions</span>
                                    </div> : ""}

        {/* Campaigns */}
        <div className="sidebar-mobile__top__item-wrapper">
          <div className="sidebar-mobile__top__item" onClick={() => {
            setExpandedRow(1)
          }}>
            <CampaignsIcon className="sidebar-mobile__top__item__list__bullet__icon" />
            {/* <div>Campaigns</div> */}
            <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Campaigns</span>
          </div>
          {/* <div className={`sidebar-mobile__top__item__sublist ${expandedRow === 1 ? "sidebar-mobile__top__item__sublist--expand" : "sidebar-mobile__top__item__sublist--collapse"}`}> */}
          <div className={`sidebar-mobile__top__item__sublist ${expandedRow === 1 ? "sidebar-mobile__top__item__sublist--expand" : ""}`}>
            <Link to={`/${ROUTE_CAMPAIGNS}`}>
              <div className={`${SHOW_ROUTE_CAMPAIGNS_OVERVIEW ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS}`)}`}>
              {/* <div className="sidebar-mobile__top__item" onClick={() => navigate(`/${ROUTE_CAMPAIGNS}`)}> */}
                  <TriwaveIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Overview</span>
              </div>
            </Link>
            <Link to={`/${ROUTE_CAMPAIGNS_02}`}>
              <div className={`${SHOW_ROUTE_CAMPAIGNS_LOCATION ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_02}`)}`}>
                  <GeographicInsightsIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Locations</span>
              </div>
            </Link>
            <Link to={`/${ROUTE_CAMPAIGNS_03}`}>
              <div className={`${SHOW_ROUTE_CAMPAIGNS_CATEGORY ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_03}`)}`}>
                  <ContentPerformanceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Categories</span>
              </div>
            </Link>

            {ROTANA_DEMO_MODE 
            ?
            <Link to={`/${ROUTE_CAMPAIGNS_01}`}>
              <div className={`sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_01}`)}`}>
                  <TargetAudienceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Audiences</span>
              </div>
            </Link>
            :
              <div className={`${SHOW_ROUTE_CAMPAIGNS_AUDIENCE ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_01}`)}`}>
                  <TargetAudienceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Audiences</span>
              </div>
            }


            <Link to={`/${ROUTE_CAMPAIGNS_05}`}>
              <div className={`${SHOW_ROUTE_CAMPAIGNS_TIMING ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_05}`)}`}>
                  <TimingIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Timing</span>
              </div>
            </Link>

            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_DEVICE || ROTANA_DEMO_MODE) && (
            <Link to={`/${ROUTE_CAMPAIGNS_06}`}>
              <div className={`${SHOW_ROUTE_CAMPAIGNS_DEVICE ? "" : "sidebar-desktop__list__bullet--greyout"} sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_06}`)}`}>
                  <DevicePerformanceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Devices</span>
              </div>
            </Link>
            )}
            
            {/* {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_CATEGORY) && (
            <Link to={`/${ROUTE_CAMPAIGNS_04}`}>
              <div className={`sidebar-mobile__top__item ${pick(`/${ROUTE_CAMPAIGNS_04}`)}`}>
                  <InterestCategoriesIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                  <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>IAB</span>
              </div>
            </Link>
            )} */}

          </div>
        </div>

                                                    {/* Subscriptions */}
                                                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_SUBSCRIPTIONS) ? <div className="sidebar-mobile__top__item-wrapper">
                                                      <div className="sidebar-mobile__top__item" onClick={() => {
                                                        setExpandedRow(2)
                                                      }}>
                                                        <SubscriptionsIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                        {/* <div>Subscriptions</div> */}
                                                        <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Subscriptions</span>
                                                      </div>
                                                      <div className={`sidebar-mobile__top__item__sublist ${expandedRow === 2 ? "sidebar-mobile__top__item__sublist--expand" : ""}`}>
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS}`)}`}>
                                                          {/* <div className="sidebar-mobile__top__item" onClick={() => navigate(`/${ROUTE_SUBSCRIPTIONS}`)}> */}
                                                              <TriwaveIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Overview</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_02}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_02}`)}`}>
                                                              <GeographicInsightsIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Locations</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_03}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_03}`)}`}>
                                                              <ContentPerformanceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Categories</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_01}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_01}`)}`}>
                                                              <TargetAudienceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Audiences</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_05}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_05}`)}`}>
                                                              <TimingIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Timing</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_06}`}> */}
                                                          <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_06}`)}`} onClick={() => navigate(`/${ROUTE_SUBSCRIPTIONS_06}`)}>
                                                              <DevicePerformanceIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Devices</span>
                                                          </div>
                                                        {/* </Link> */}
                                                        {/* <Link to={`/${ROUTE_SUBSCRIPTIONS_04}`}> */}
                                                          {/* <div className="sidebar-mobile__top__item" onClick={() => navigate(`/${ROUTE_SUBSCRIPTIONS_04}`)}> */}
                                                          {/* <div className={`sidebar-mobile__top__item sidebar-mobile__top__item--inactive ${pick(`/${ROUTE_SUBSCRIPTIONS_04}`)}`}>
                                                              <InterestCategoriesIcon className="sidebar-mobile__top__item__list__bullet__icon" />
                                                              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>IAB</span>
                                                          </div> */}
                                                        {/* </Link> */}

                                                      </div>
                                                    </div> : ""}

        {/* Contents */}
        {((SHOW_N_ENABLE_ALL && ROTANA_DEMO_MODE) || (ENABLE_ROUTE_CONTENT && !ROTANA_DEMO_MODE) || ROTANA_DEMO_MODE) && (
        <WrapLink route={`/${ROUTE_CONTENT}`} shouldWrap={!ROTANA_DEMO_MODE}>
          <div className={"sidebar-mobile__top__item__list__bullet__icon--disabled"}>
            <VideoIcon className={`sidebar-mobile__top__item__list__bullet__icon`}/>
            <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Contents</span>
          </div> 
        </WrapLink>
        )}

        {/* Calculator */}
        {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CALCULATOR) && (
        // <Link to={`/${ROUTE_CALCULATOR}`}>
        <WrapLink route={`/${ROUTE_CALCULATOR}`} shouldWrap={!ROTANA_DEMO_MODE}>
          <div className={`sidebar-mobile__top__item ${pick(`/${ROUTE_CALCULATOR}`)} ${ROTANA_DEMO_MODE ? "sidebar-desktop__list__bullet__icon--disabled" : ""}`} onClick={() => {
            setExpandedRow(0)
          }}>
              <CalculatorIcon className="sidebar-mobile__top__item__list__bullet__icon" />
              <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Calculator</span>
          </div>
        </WrapLink>
        )}
        {/* {ROTANA_DEMO_MODE ? <div className={"sidebar-mobile__top__item__list__bullet__icon--disabled"}>
          <CalculatorIcon className={`sidebar-mobile__top__item__list__bullet__icon`}/>
          <span className={`sidebar-mobile__top__item__list__bullet__label text-m`}>Calculator</span>
        </div> : ""} */}


        
      </div>

      {/* Bottom */}
      <div className="sidebar-mobile__bottom">
        <div className="sidebar-mobile__bottom__avatar">
          <Avatar wide={mobile}/* userCard={authContext?.userCard} */ />
        </div>
      </div>

    </nav>

    :

    <nav className="sidebar-desktop">

      {/* Head */}
      <Link to={`/${ROUTE_HOME}`}>
        <div className={"sidebar-desktop__head"}>
          <NucleusLogoIcon className="sidebar-desktop__head__icon" />
        </div>
      </Link>

      {/* Main */}
      <ul className="sidebar-desktop__list">

        {/* {ROTANA_DEMO_MODE ? <li className={"sidebar-desktop__list__bullet__icon--disabled"}>
          <HomeIcon className={`sidebar-desktop__list__bullet__icon`}/>
          <SubscriptionsIcon className={`sidebar-desktop__list__bullet__icon`}/>
        </li> : ""} */}
        
        {(ENABLE_ROUTE_HOME || ROTANA_DEMO_MODE) && (
          <WrapLink route={`/${ROUTE_HOME}`} shouldWrap={!ROTANA_DEMO_MODE}>
            <li className={`${pick(`/${ROUTE_HOME}`)} ${ROTANA_DEMO_MODE ? "sidebar-desktop__list__bullet__icon--disabled" : ""}`}>
              <HomeIcon className="sidebar-desktop__list__bullet__icon" />
              <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
            </li>
          </WrapLink>
        )}

        {/* {(ENABLE_ROUTE_SUBSCRIPTIONS || ROTANA_DEMO_MODE) && (
          <WrapLink route={`/${ROUTE_SUBSCRIPTIONS}`} shouldWrap={!ROTANA_DEMO_MODE}>
            <li className={`${pick(`/${ROUTE_SUBSCRIPTIONS}`)} ${ROTANA_DEMO_MODE ? "sidebar-desktop__list__bullet__icon--disabled" : ""}`}>
              <SubscriptionsIcon className="sidebar-desktop__list__bullet__icon" />
              <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
            </li>
          </WrapLink>
        )} */}

        <WrapLink route={`/${ROUTE_CAMPAIGNS}`} shouldWrap={true}>
          <li className={`${pick(`/${ROUTE_CAMPAIGNS}`)}`}>
            <CampaignsIcon className="sidebar-desktop__list__bullet__icon" />
            <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
          </li>
        </WrapLink>


        {((SHOW_N_ENABLE_ALL && ROTANA_DEMO_MODE) || (ENABLE_ROUTE_VIDEO && !ROTANA_DEMO_MODE) || ROTANA_DEMO_MODE) && (
          <WrapLink route={`/${ROUTE_VIDEO}`} shouldWrap={!ROTANA_DEMO_MODE}>
            <li className={`${pick(`/${ROUTE_VIDEO}`)} ${ROTANA_DEMO_MODE ? "sidebar-desktop__list__bullet__icon--disabled" : ""}`}>
              <VideoIcon className="sidebar-desktop__list__bullet__icon" />
              <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
            </li>
          </WrapLink>
        )}

        {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CALCULATOR || ROTANA_DEMO_MODE) && (
          // <Link to={`/${ROUTE_CALCULATOR}`}>
          <WrapLink route={`/${ROUTE_CALCULATOR}`} shouldWrap={!ROTANA_DEMO_MODE}>
            <li className={`${pick(`/${ROUTE_CALCULATOR}`)} ${ROTANA_DEMO_MODE ? "sidebar-desktop__list__bullet__icon--disabled" : ""}`}>
              <CalculatorIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon--sfpatch sidebar-desktop__list__bullet__icon-rotated" />
              <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
            </li>
          </WrapLink>
        )}


      </ul>

      {/* Foot */}
      <div className="sidebar-desktop__foot">
        <ul className="sidebar-desktop__list">

          {ENABLE_ROUTE_HELP && (
            <Link to={`/${ROUTE_HELP}`}>
              <li className={pick(`/${ROUTE_HELP}`)}>
                <HelpIcon className="sidebar-desktop__list__bullet__icon" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
              </li>
            </Link>
          )}

          {ENABLE_ROUTE_SETTINGS && (
            <Link to={`/${ROUTE_SETTINGS}`}>
              <li className={pick(`/${ROUTE_SETTINGS}`)}>
                <SettingsIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon--sfpatch sidebar-desktop__list__bullet__icon-bigger" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>&nbsp;</span>
              </li>
            </Link>
          )}
        </ul>
        <div className="sidebar-desktop__foot__avatar">
          <Avatar /* userCard={authContext?.userCard} */ />
        </div>
      </div>

      {/* Subsidebar-desktop */}
      {["campaigns", "subscriptions"].includes(segment_this) ? <div className="sidebar-desktop__extended">
        <div className="sidebar-desktop__header text-xl">{segment_this.replace(segment_this?.[0], segment_this?.[0].toUpperCase())}
          {/* <span className="text-s"
            onClick={() => navigate("/" + segment_this)}>{pathname.match(/\//g).length > 1 ? "← Overview" : ""}</span> */}
        </div>

        {segment_this === "campaigns" ?

          <ul className="sidebar-desktop__list">


            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_OVERVIEW) && (
              <WrapLink route={`/${ROUTE_CAMPAIGNS}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_OVERVIEW && ENABLE_ROUTE_CAMPAIGNS_OVERVIEW}>
                <li className={`${SHOW_ROUTE_CAMPAIGNS_OVERVIEW ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS}`)}`}>
                  <TriwaveIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon" />
                  <span className={`sidebar-desktop__list__bullet__label text-m`}>Overview</span>
                </li>
              </WrapLink>
            )}

            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_LOCATION) && (
              <WrapLink route={`/${ROUTE_CAMPAIGNS_02}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_LOCATION && ENABLE_ROUTE_CAMPAIGNS_LOCATION}>
                <li className={`${SHOW_ROUTE_CAMPAIGNS_LOCATION ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_02}`)}`}>
                  <GeographicInsightsIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                  <span className={`sidebar-desktop__list__bullet__label text-m`}>Locations</span>
                </li>
              </WrapLink>
            )}

            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_CATEGORY) && (
              <WrapLink route={`/${ROUTE_CAMPAIGNS_03}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_CATEGORY && ENABLE_ROUTE_CAMPAIGNS_CATEGORY}>
                <li className={`${SHOW_ROUTE_CAMPAIGNS_CATEGORY ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_03}`)}`}>
                  <ContentPerformanceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                  <span className={`sidebar-desktop__list__bullet__label text-m`}>Categories</span>
                </li>
              </WrapLink>
            )}

            {ROTANA_DEMO_MODE 
            ?
            <Link to={`/${ROUTE_CAMPAIGNS_01}`}>
              <li className={`${pick(`/${ROUTE_CAMPAIGNS_01}`)}`}>
                <TargetAudienceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>Audiences</span>
              </li>
            </Link>
            :
            (SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_AUDIENCE) && (
            <WrapLink route={`/${ROUTE_CAMPAIGNS_01}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_AUDIENCE && ENABLE_ROUTE_CAMPAIGNS_AUDIENCE}>
              <li className={`${SHOW_ROUTE_CAMPAIGNS_AUDIENCE ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_01}`)}`}>
                <TargetAudienceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>Audiences</span>
              </li>
            </WrapLink>
            )
            }
            
            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_TIMING) && (
            <WrapLink route={`/${ROUTE_CAMPAIGNS_05}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_TIMING && ENABLE_ROUTE_CAMPAIGNS_TIMING}>
              <li className={`${SHOW_ROUTE_CAMPAIGNS_TIMING ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_05}`)}`}>
                <TimingIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>Timing</span>
              </li>
            </WrapLink>
            )}
            {/* <Link to={`/${ROUTE_CAMPAIGNS_05}`}>
              <li className={`${SHOW_ROUTE_CAMPAIGNS_TIMING ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_05}`)}`}>
                <TimingIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                <span className={`sidebar-desktop__list__bullet__label text-m`}>Timing</span>
              </li>
            </Link> */}
            
            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_DEVICE) && (
            <WrapLink route={`/${ROUTE_CAMPAIGNS_06}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_DEVICE && ENABLE_ROUTE_CAMPAIGNS_DEVICE}>
              <li className={`${SHOW_ROUTE_CAMPAIGNS_DEVICE ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_06}`)}`}>
                  <DevicePerformanceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                  <span className={`sidebar-desktop__list__bullet__label text-m`}>Devices</span>
                </li>
              </WrapLink>
            )}
            {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_IAB) && (
              <WrapLink route={`/${ROUTE_CAMPAIGNS_04}`} shouldWrap={SHOW_ROUTE_CAMPAIGNS_IAB && ENABLE_ROUTE_CAMPAIGNS_IAB}>
                <li className={`${SHOW_ROUTE_CAMPAIGNS_IAB ? "" : "sidebar-desktop__list__bullet--greyout"} ${pick(`/${ROUTE_CAMPAIGNS_04}`)}`}>
                  <InterestCategoriesIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                  <span className={`sidebar-desktop__list__bullet__label text-m`}>IAB</span>
                </li>
              </WrapLink>
            )}
          </ul>

          :
                                                segment_this === "subscriptions" ?

                                                  <ul className="sidebar-desktop__list">
                                                    <Link to={`/${ROUTE_CAMPAIGNS}`}>
                                                      <li className={pick(`/${ROUTE_CAMPAIGNS}`)}>
                                                        <TriwaveIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Overview</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_CAMPAIGNS_02}`}>
                                                      <li className={pick(`/${ROUTE_CAMPAIGNS_02}`)}>
                                                        <GeographicInsightsIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Locations</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_CAMPAIGNS_03}`}>
                                                      <li className={pick(`/${ROUTE_CAMPAIGNS_03}`)}>
                                                        <ContentPerformanceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Categories</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_CAMPAIGNS_01}`}>
                                                      <li className={pick(`/${ROUTE_CAMPAIGNS_01}`)}>
                                                        <TargetAudienceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Target audience</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_SUBSCRIPTIONS_02}`}>
                                                      <li className={pick(`/${ROUTE_SUBSCRIPTIONS_02}`)}>
                                                        <GeographicInsightsIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Geographic insights</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_SUBSCRIPTIONS_03}`}>
                                                      <li className={pick(`/${ROUTE_SUBSCRIPTIONS_03}`)}>
                                                        <ContentPerformanceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Content performance</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_SUBSCRIPTIONS_04}`}>
                                                      <li className={pick(`/${ROUTE_SUBSCRIPTIONS_04}`)}>
                                                        <InterestCategoriesIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Interest categories</span>
                                                      </li>
                                                    </Link>
                                                    <Link to={`/${ROUTE_SUBSCRIPTIONS_05}`}>
                                                      <li className={pick(`/${ROUTE_SUBSCRIPTIONS_05}`)}>
                                                        <TimingIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                        <span className={`sidebar-desktop__list__bullet__label text-m`}>Timing</span>
                                                      </li>
                                                    </Link>
                                                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_DEVICE) && (
                                                      <Link to={`/${ROUTE_SUBSCRIPTIONS_06}`}>
                                                        <li className={pick(`/${ROUTE_SUBSCRIPTIONS_06}`)}>
                                                          <DevicePerformanceIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                          <span className={`sidebar-desktop__list__bullet__label text-m`}>Devices</span>
                                                        </li>
                                                      </Link>
                                                    )}
                                                    {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_CATEGORY) && (
                                                      <Link to={`/${ROUTE_SUBSCRIPTIONS_04}`}>
                                                        <li className={pick(`/${ROUTE_SUBSCRIPTIONS_04}`)}>
                                                          <InterestCategoriesIcon className="sidebar-desktop__list__bullet__icon sidebar-desktop__list__bullet__icon-rotated" />
                                                          <span className={`sidebar-desktop__list__bullet__label text-m`}>IAB</span>
                                                        </li>
                                                      </Link>
                                                    )}
                                                  </ul>

                                                  : null
        }
        
      </div> : <></>}

    </nav>
  )
} 
