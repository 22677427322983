import {
  useEffect,
  useState,
  useRef
} from "react"

import { 
  useSearchParams
  // useNavigate, 
  // createSearchParams 
} from "react-router-dom"

// Contexts
import { 
  useGlobals
} from "contexts"

import {
  useNavigate
} from "react-router-dom";

import {
  Tick,
  PopoverHML,
  TitleCard,
  Heartbeat,
  Persona,
  Dropdown
  // Counter
} from "elements"

import {
  ROTANA_DEMO_MODE
} from "util/const"

import "./Chart.css"

export const Chart = ({data=[], text={}, mode=0}) => {
  const navigate = useNavigate();
  const globals = useGlobals()

  // const [searchParams, setSearchParams] = useSearchParams()

  // TODO: Find a cleaner way to 
  // const [group, setGroup] = useState(0)

  // const period = useRef(globals?.context?.selected?.[1] || "am")// TODO: No need to have a vector here
  const period = useRef(globals?.context?.temp_selected || "am")// TODO: No need to have a vector here

  // const cohort = useRef(globals?.context?.selected?.[0] || "_")
  // const cohort = useRef(globals?.context?.dropdown_selection || "_")
  // const cohort = globals.get("dropdown_selection") || "_"
  const cohort = globals?.context?.dropdown_selection || "_"

  const yflags = text.y_flags || []
  const xflags = text.x_flags || []

  const maxY = Math.max(...yflags?.map((_) => !Number(_) ? parseFloat(_.replaceAll(/\D+/g, "")) : _ )) 
  const dataBox = useRef()

  const p_unit = yflags[0]?.[0]
  const s_unit = yflags[0]?.[yflags.length - 1]
  const prefix = ["$"].includes(p_unit) ? p_unit : ""
  const suffix = ["K"].includes(s_unit) ? s_unit : ""
  const x_divs = new Array(xflags.length || 100).fill()/* 100 divs if no divs (e.g. Bubbles) */
  // const x_divs = new Array(!xflags.length ? data[0]?.length || 100 : xflags.length).fill()
  const y_divs = new Array(yflags?.length - +isNaN(maxY) + +(cohort !== "_")/* TODO: Not here */)

  // TODO: No need for a prop here (so merge as part of the CSS styling)
  // const x_mode = ["line", "dash", "dash", "line", "", "", "dash", "dash"][mode]
  const x_mode = "line"
  // const y_mode = ["", "", "dash", ""][mode]
  const y_mode = ["", "", "line", ""][mode]

  useEffect(() => {
    data && setTimeout(() => {
      dataBox.current.classList.add("chart-xygrid__col--inflate")// TEST: '.remove' by default (vs '.add')
    }, 0)
  }, [data])
  if (!data) return
  
  // TODO: Get rid of this workaround
  const newDotMatrixRow = mode === 3 ? cohort === "_" : true

  const ROTANA_DEMO_MODE_ = true // -> Manual override

  return <>{/* div onClick={() => group ? setGroup(0) : null} */}
      <TitleCard title={text.title} subtitle={text.subtitle} className={text.title_modifier}/>
      {mode === 5 && <div className={`chart-legend text-xs`}>
        <div><span></span><span>SVOD</span></div>
        <div><span></span><span>AVOD</span></div>
      </div>}

      {<div className={`chart-buttons`}>
        {mode === 3 && <div className={`chart-buttons__switch ${ROTANA_DEMO_MODE_ ? "chart-buttons__switch--disabled" : ""}`}>
          {/* <span className={`chart-buttons__switch__am ${period.current === "am" ? "chart-buttons__switch__am--selected" : ""}`} onClick={() => globals.set("selected", [cohort, period.current = "am"])}>AM</span>
          <span className={`chart-buttons__switch__pm ${period.current === "pm" ? "chart-buttons__switch__pm--selected" : ""}`} onClick={() => globals.set("selected", [cohort, period.current = "pm"])}>PM</span> */}
        
          <span className={`chart-buttons__switch__am text-xs-b ${period.current === "am" ? "chart-buttons__switch__am--selected" : ""}`} onClick={() => {
            globals.set("temp_selected", period.current = "am")
          }}>AM</span>
          <span className={`chart-buttons__switch__pm text-xs-b ${period.current === "pm" ? "chart-buttons__switch__pm--selected" : ""}`} onClick={() => {
            globals.set("temp_selected", period.current = "pm")
          }}>PM</span>

          {/* TODO: Have filtering parameters passed as props (e.g. 'by=["am", "pm"]') instead of arbitrary [Timing section in this case] hardcoding -> For class just do "...switch__INDEX--selected" */}
          {/* <span className={`chart-buttons__switch__am ${period.current === "am" ? "chart-buttons__switch__am--selected" : ""}`} onClick={() => "?" + setSearchParams({"by": "am"})}>AM</span>
          <span className={`chart-buttons__switch__pm ${period.current === "pm" ? "chart-buttons__switch__pm--selected" : ""}`} onClick={() => "?" + setSearchParams({"by": "pm"})}>PM</span>
          */}
        </div>}

        {/* TODO: Separate component */}
        <div className={`${ROTANA_DEMO_MODE_ ? "chart-buttons--hide" : ""}`}>
          <Dropdown mode={1} />
        </div>
        {/* <div className={`chart-buttons__dropdown`} onClick={() => setGroup(!group)}>
          <div className={`chart-buttons__dropdown__flag chart-buttons__dropdown__flag-${cohort}`}></div>
          <span>Group distribution</span><div className={"chart-buttons__dropdown-arrow-" + (group ? "up" : "dn")}></div>
          {group ? <div className="chart-buttons__dropdown__table">
            <div onClick={() => globals.set("dropdown_selection", [cohort = "_", period.current])}>
              <div></div><div className="text-xs">All groups</div><Heartbeat/><div className="text-xxs-b">0-100</div>
            </div>
            <div onClick={() => globals.set("dropdown_selection", [cohort = "high", period.current])}>
              <div></div><div className="text-xs">High engagement</div><Heartbeat/><div className="text-xxs-b">71-100</div>
            </div>
            <div onClick={() => globals.set("dropdown_selection", [cohort = "medium", period.current])}>
              <div></div><div className="text-xs">Medium engagement</div><Heartbeat/><div className="text-xxs-b">31-70</div>
            </div>
            <div onClick={() => globals.set("dropdown_selection", [cohort = "low", period.current])}>
              <div></div><div className="text-xs">Low engagement</div><Heartbeat/><div className="text-xxs-b">0-30</div>
            </div>
          </div> : ""}
        </div> */}

      </div>}

      <div className={`chart 
        ${[3].includes(mode) ? "chart--flat" : ""} 
        ${[1, 2, 3, 4, 5, 6, 7].includes(mode) ? "chart--kiss" : "" /* Legacy: [6].includes(mode) ? "chart--kiss" : "" */} 
        ${[4].includes(mode) ? "chart--axesless" : ""}
        ${[6, 7].includes(mode) ? "chart--buttonfull-bars" : ""}
        ${[3].includes(mode) ? "chart--buttonfull-matrix" : ""}
        `}>
        
        <div className="chart-ytitle text-xs">
          <b>{text.y_label}</b>
        </div>

        {![4].includes(mode) && <div className="chart-yflags text-xs">
        {[...yflags].concat(!(newDotMatrixRow) &&        true/*&& cohort_above_only*/ ? [""] : []).reverse().map((label, index) => <div 
          key={index}
          >{label}</div>)}

          {/* cohort below */}
          {/* {y_divs.length === yflags.length && <div>{cohort ? "" : "0"}</div>}  */}

          {/* cohort above */}
          {/* {y_divs.length === yflags.length && newDotMatrixRow && <div>0</div>} */}
          {(mode === 3 ? y_divs.length === yflags.length && newDotMatrixRow : true) ? <div>0</div> : ""}
          
        </div>}

        <div className={`chart-xygrid`} >
          <div className={`chart-xygrid__row--${x_mode} chart-xygrid__row`}>
            {y_divs.fill().map((_, index) => {
              return <div key={index} className={`chart-xygrid__row__cell ${true/* && cohort_above_only */ && (!newDotMatrixRow) && !index ? "chart-xygrid__row__cell--hide" : ""}`}></div>
            })}
          </div>

          <div className={`chart-xygrid__col--${y_mode} chart-xygrid__col`} ref={dataBox}>
            {x_divs.map((_, index) => {

              return <div key={index} className={`chart-xygrid__col__cell ${mode === 2 && ((index + 1) % 20) ? "chart-xygrid__col__cell--skip" : ""}`}>

                {/* Batch of bars (e.g. twins) */}       
                {mode === 0 
                && <div className="chart-xygrid__col__cell__batch" style={{

                    // TODO: Allow support for non-linear y-axis scales (e.g. log, [$16:$25] ranges, etc...)
                    "--__chart-set1": ((data[index][0] / maxY) * 100) + "%",
                    "--__chart-set2": ((data[index][1] / maxY) * 100) + "%"
                  }}>
                  <div className="chart-xygrid__col__cell__batch__lmost"></div>
                  <div className="chart-xygrid__col__cell__batch__rmost"></div>
                </div>}

                {/* Stacked bars (can be just one/single/classic too) */}
                {mode === 1 &&
                <div className="chart-xygrid__col__cell__stack" style={{
                  "--__chart-set1": ((data[index][1] / maxY) * 100) + "%",// TODO: Convert abs values to percentual ones
                  "--__chart-factor": Math.abs(data[index][0] / data[index][1]) * 100 + "%"
                }}>
                  <div className="chart-xygrid__col__cell__stack__tick">{data[index][0] !== 0 ? <Tick value={prefix + data[index][0] + suffix}/> : ""}</div>
                  <div className="chart-xygrid__col__cell__stack__items">
                    <div className={`chart-xygrid__col__cell__stack__items__delta ${data[index][0] > 0 ? "" : "chart-xygrid__col__cell__stack__items__delta--flip"}`}></div>
                    <div className={`chart-xygrid__col__cell__stack__items__inset`}>
                      <Heartbeat/>
                      <span className="text-xs">{data[index][2]}</span>
                    </div>
                  </div>
                </div>} 

                {/* Bubbles */}
                {mode === 2 && data[index] && /* Skip if empty -> NEXT: if (data["dp" + index]) exists then get data */
                  <div className="aaaaa chart-xygrid__col__cell__bubbles" 
                    style={{
                      "--__chart-set1": ((data[index][0] / maxY) * 100) + "%",// TODO: Fix/rename (and group as data-sets outside x/y): Data sets are ~all X
                      "--__chart-set2": (
                        ROTANA_DEMO_MODE 
                        ? 
                        (data[index][1] > 10 ? (data[index][1] > 100 ? (data[index][1] > 1000 ? (data[index][1] > 10000 ? 30 : 10) : 25) : 20) : 15)
                        : 
                        data[index][1]) 
                        + "%"
                    }}
                      onMouseOver={(event) => {
                        event.currentTarget.parentElement.classList.add(`popoverhml--show`)
                      }}
                      onMouseOut={(event) => {
                        event.currentTarget.parentElement.classList.remove(`popoverhml--show`)
                      }}
                    >
                    <Persona id={data[index][2]}/>
                      <PopoverHML name={data[index][2]} data={ROTANA_DEMO_MODE_ ? [100, 100, 100] : [25, 39, 37]} flip={false} tail={150} kiss={true}/>

                      {/* TODO: Make component out of this block (as w/ the popovers */}
                      {ROTANA_DEMO_MODE_ ? "" : <div className={`chart-xygrid__col__cell__dots__dot__inset chart-xygrid__col__cell__dots__dot__inset-${cohort}`}>
                        <span className="text-xxs-b">{data[index][1] + "%"}</span>
                      </div>}
                  </div>
                }
  
                {/* Dot matrix */}
                {mode === 3 && data.length
                  ?
                  <div className={`chart-xygrid__col__cell__dots ${!(newDotMatrixRow) ? "chart-xygrid__col__cell__dots--xtra" : ""}`}>
                    {/* Orbs above */}
                    {!(newDotMatrixRow) ? <div className={`chart-xygrid__col__cell__dots__dot__inset chart-xygrid__col__cell__dots__dot__inset-${cohort}`}>
                      <span className="text-xxs-b">{Math.round(data[index][7]) + "%"}</span>
                    </div> : <></>}
                    {/* Dots */}
                    {new Array(7).fill().map((_, subindex) =>
                      <div key={subindex}>
                        <svg className={`chart-xygrid__col__cell__dots__dot--zoom-${data[index][subindex]}`} viewBox="0 0 100 100">
                          <circle cx="50" cy="50" r="50"/>
                        </svg>
                      </div>
                    )}
                    {/* Orbs below */}
                    {/* {!(newDotMatrixRow) ? <div className={`chart-xygrid__col__cell__dots__dot__inset`}>
                      <span className="text-xxs-b">{data[index][2] + "%"}</span>
                    </div> : <></>} */}
                  </div>
                  :
                  ""  
                }

                {/* Stacked Bars (axisless personas) */}
                {mode === 4 &&
                  <div className="chart-xygrid__col__cell__persona" style={{
                    "--__chart-set0": (data[index][1] + 1) + "%",
                    "--__chart-set1": data[index][2] + "%",
                    "--__chart-set2": data[index][3] + "%",
                    "--__chart-set3": data[index][4] + "%",
                    "--__chart-set4": (100 / ((data[index][2] + data[index][3]) / data[index][2])) + "%"
                  }}>
                  <div className="chart-xygrid__col__cell__persona__orb">
                    
                    {/* Multiformat support */}
                    {typeof data[index][0] === "object" 
                    ?
                  
                    data[index][0]
                    // <svg viewBox="0 0 100 100">
                    //   <circle cx="50" cy="50" r="50"/>
                    // </svg>
                    : 
                    (data[index][0].length > 10 ? <img src={data[index][0]} alt=""/> : <div className="text-s"><b>{data[index][0]}</b></div>)
                    }

                    <div className="text-xs">{xflags[index]}</div>
                  </div>
                  <div className="chart-xygrid__col__cell__persona__items text-xxs">
                    <div>
                      <div className="chart-xygrid__col__cell__persona__items__item">
                        <span>Chrurn</span>
                        <span><b>{data[index][2] + "%"}</b></span>
                        {/* <span><b><Counter value={data[index][2] + "%"} duration={1600} delay={200}/></b></span> */}
                      </div>
                      <div className="chart-xygrid__col__cell__persona__items__item">
                        <span>SVOD</span>
                        <span><b>{data[index][3] + "%"}</b></span>
                        {/* <span><b><Counter value={data[index][3] + "%"} duration={1600} delay={200}/></b></span> */}
                      </div>
                    </div>
                    {!data[index][3]
                    ? 
                    <div className={`chart-xygrid__col__cell__persona__items__item chart-xygrid__col__cell__persona__items--fill chart-xygrid__col__cell__persona__items--fill-${data[index][1]}`}></div>
                    :
                    <div className="chart-xygrid__col__cell__persona__items__item">
                      <span>AVOD</span>
                      <span><b>{data[index][4] + "%"}</b></span>
                      {/* <span><b><Counter value={data[index][4] + "%"} duration={1600} delay={200}/></b></span> */}
                    </div>
                    }
                  </div>
                </div>} 

                {/* Stacked bars ([safer/cleaner] revamped alternative to #1) */}
                {mode === 5 &&
                <div className="chart-xygrid__col__cell__stack-re" style={{
                  "--__chart-set1": (data[index]?.[0] || 0) / maxY,
                  "--__chart-set2": (data[index]?.[1] || 0) / maxY
                  }}>
                  <div className={`chart-xygrid__col__cell__stack-re__item text-m`}>
                    <span>{data[index][0] + "%"}</span>
                  </div>
                </div>}
                
                {/* Bars (minimal) */}
                {mode === 6 && data.length > 0 &&
                <div className="chart-xygrid__col__cell__bars-min" style={{
                  "--__chart-set1": (data[index] || 0) / maxY
                  }}>
                </div>}  

               {/* Single bars (w/ cohort & popups) */}
               {mode === 7 && xflags.length > 0 &&
                <div className="chart-xygrid__col__cell__stack_orb" 
                  style={{
                    "--__chart-set1": ((data?.[index]?.[0] / maxY) * 100) + "%"
                  }}
                    onMouseOver={(event) => {
                      event.target.parentElement.classList.add(`popoverhml--show`)
                    }}
                    onMouseOut={(event) => {
                      event.target.parentElement.classList.remove(`popoverhml--show`)
                    }}
                  >
                  <div className="chart-xygrid__col__cell__stack_orb__items">
                    <div className={`chart-xygrid__col__cell__stack_orb__items__inset chart-xygrid__col__cell__stack_orb__items__inset-${cohort}`}>
                      
                      {/* Heartbeat orb */}
                      <span className="text-xxs-b">{Math.round(ROTANA_DEMO_MODE ? data[index]?.[1] : data[index]?.[1]?.[{"high": 0, "medium": 1, "low": 2}[cohort]]) + "%"}</span> 

                    </div>
                  </div>
                  <PopoverHML name={xflags[index]} data={ROTANA_DEMO_MODE ? data[index]?.[2] : data[index]?.[1]} flip={false} tail={150}/>
                </div>} 

              </div>



            })}
          </div>
        </div>
        {(xflags.length > 0 && ![4].includes(mode)) &&
        <div className="chart-xflags text-xs">
          {xflags.map((label, index) => <div 
            key={index}
            >{label?.replaceAll(/* TODO: This shouldn't go here */"_", " ")}</div>)}
        </div>}


        {(![].includes(mode)) &&
        <div className={`chart-xtitle ${mode === 2 ? "chart-xtitle--flex" : ""} ?${mode === 4 ? "chart-xtitle--link" : ""} text-xs`} onClick={() => navigate("/somewhere")}>
          {mode === 2 ? <span><Heartbeat/>0</span> : ""}
          <span><b>{text.x_label}</b></span>
          {mode === 2 ? <span><Heartbeat/>100</span> : ""}
        </div>}
      </div>
    </>
}