import {
  useNavigate
} from "react-router-dom"

// Containers
import { 
  Layout,
  Grid
} from "containers"

// Components
import { 
  Topbar,
  Wheel,
  ChartBars,
  TableSort,
  Details,
  PieArc,
  WheelAltFull,
  ChartHistory,
  ChartLine
} from "components"

// Contexts
import {
  useGlobals
} from "contexts"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Home-wide styling
import "./Campaigns.css"

import hardcodedPic0 from "assets/_temp/campaigns/lego.png"
import hardcodedPic1 from "assets/_temp/campaigns/justdontquit.png"
import hardcodedPic2 from "assets/_temp/campaigns/olympics.png"
import hardcodedPic3 from "assets/_temp/campaigns/absolutperfection.png"
import hardcodedPic4 from "assets/_temp/campaigns/baby.png"
import hardcodedPic5 from "assets/_temp/campaigns/thinkdifferent.png"

// Campaigns assembly
export const Campaigns = ({ready}) => {
  const navigate = useNavigate()
  const globals = useGlobals()
  const group = globals?.context?.dropdown_selection || "_"

  let arr_arc = []
  let arr_bar = []
  let arr_top = new Array(5).fill([0, 0])
  let arr_dat_mini = []
  let obj_top = {}
  //let obj_frt = []
  let obj_val1 = []
  let obj_val2 = []
  let obj_val3 = []

  if (ready) {

    const data = ROTANA_DEMO_MODE 
    ? 
    globals?.context?.data_json_test?.overview 
    : 
    globals?.context?.data_json_test?.postcampaigns?.campaign0?.overview?.[group]

    arr_bar = ROTANA_DEMO_MODE ? data?.scores : data?.engagement
    arr_arc = [[arr_bar[0], -1]/*, [35, 0]*/]
    const cards = data.cards
    arr_top = ROTANA_DEMO_MODE 
      ? [cards.reach, cards.sessions, cards.session_len, cards.viz_pct]
      // : [cards.sessions, cards.reach, cards.avg_view_time, cards.viewability, cards.cpm]
      : [cards.impressions, cards.unique_reach, cards.frequency, cards.avg_view_time, cards.viewability]
    arr_dat_mini = ROTANA_DEMO_MODE ? data.table : data.minitables
    // obj_top = data.details
    obj_top = globals?.context?.data_json_test?.postcampaigns?.campaign0?.details
    // obj_frt = data.fillrate
    // obj_val1 = [data?.engagement, data?.ad_attention]
    obj_val1 = /* Get last values from the historical series */[data?.engagement?.[data?.engagement?.length - 1], data?.ad_attention?.[data?.ad_attention?.length - 1]]
    obj_val2 = [data?.ads_sent?.[0], data?.ads_requested?.[0], data?.fill_rate?.[0]]


    obj_val3 = data?.total_sessions


  }
  // if (!arr_dat_mini?.length) return

  // ---------------- DUPLICATED (to be converted into a separated layout component)
  const selectedCampaign = globals?.context?.dropdown_selection_campaigns
  const undefinedCampaign = [undefined, "allcampaigns"].includes(selectedCampaign)
  // if (!ROTANA_DEMO_MODE) {
  //   obj_top.name = {
  //     "allcampaigns": "All campaigns",
  //     "justdoit": "Just Do It",
  //     "justdontquit": "Just Don't Quit"
  //   }[selectedCampaign]
  // }
  // ---------------- DUPLICATED (to be converted into a separated layout component)

  // TODO: Build a reusable 'report' wrapper template out of this
  return (
    <Layout header={{
      "title": "Ads Overview",
      "search": ""
    }}>

      {!ROTANA_DEMO_MODE && !undefinedCampaign ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Overview</span>
        <span>{obj_top?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Overview</div>}
     

      {ROTANA_DEMO_MODE || undefinedCampaign ? "" : <Grid
        content={[
          <Details data={obj_top}/>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 0,
          h: 320
        }}
        flat={true} 
      />
      }

      <Grid
        content={[
          ROTANA_DEMO_MODE 
          ? 
          <Wheel
            text={{
              title: "Heartbeat",
              subtitle: ""
            }}
            data={arr_arc}
            mode={1}
          /> : <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Engagement & ad attention score</span>
              </div>
              <div className={"minibox-body text-xs"}>
                <WheelAltFull /* pct={obj_frt[0]} val={obj_frt[1]} */ data={obj_val1}/>
              </div>  
            </div>,


          ROTANA_DEMO_MODE ? "" : <div className="minibox-wrapper minibox-wrapper-alt">
            <div className="minibox-head">
              <span className="text-xs-b">Total sessions</span>
            </div>
            <div className={"minibox-body text-xs"}>
              {/* <ChartHistory data={[]}/> */}


              <ChartLine data={/* TODO: Decouple text from data (the same way we do for the newer components) */
                {
                  // scale: ["W1", "W2", "W3", "W4", "W5"],
                  // curve: !ready ? (new Array(5).fill(-1)) : obj_val3 //[10, 120000, 140000, 180000, 50000]

                  scale: ["W1", "", "", "", "", "", "", "W2", "", "", "", "", "", "", "W3", "", "", "", "", "", "", "W4", "", "", "", "", "", "", "W5"],
                  curve: !ready ? (new Array(29).fill(-1)) : obj_val3
                }
              }/>



            </div>
          </div>,
          
            
          ready/* Here too to animate bars on 1st load */ ? 
            ROTANA_DEMO_MODE ? <ChartBars 
              text={{
                title: "History"
                // subtitle: "Your audience engagement over time"
              }}
              data={arr_bar}
              disabled={!!ROTANA_DEMO_MODE}
            /> : 
            
            // TODO: Make a layout wrapper component out of this (best as part of the Grid's boxes)
            <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Fill rate</span>
              </div>
              <div className={"minibox-body"}>
                <PieArc /* pct={obj_frt[0]} val={obj_frt[1]} */ values={obj_val2} percentage={obj_val2?.[2]}/>
              </div>
            </div>
          
          : ""
        ]} 
        template={ROTANA_DEMO_MODE 
          ? `
            0 1 1
            ` 
          : `
            0 1 2
            `}
        cellSize={{
          w: 255,
          h: 320
        }}
      />

      {(ROTANA_DEMO_MODE || !undefinedCampaign) ? "" : <Grid
        content={[
          !ready ? "" :
            <div className="minibox-wrapper">
              <div className="minibox-head">
                <span className="text-xs-b">Top campaigns</span>
              </div>
              <div className={"minibox-body minibox-body-campaigns text-xs"}>
                {/* <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign0")}> */}
                <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign0")}>
                  <img src={hardcodedPic0/* data?.image */} alt={obj_top?.name}/>
                  {/* <div><span className={"text-xs-b"}>{obj_top?.brand}</span>:&nbsp;<span className={"text-xs"}>{obj_top?.name}</span></div> */}
                  <div><span className={"text-xs"}>{obj_top?.name}</span></div>
                </div>
                <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign1")}>
                  <img src={hardcodedPic1/* data?.image */} alt={obj_top?.name}/>
                  <div><span className={"text-xs"}>{"Just Don't Quit"}</span></div>
                </div>
                <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign2")}>
                  <img src={hardcodedPic2/* data?.image */} alt={obj_top?.name}/>
                  <div><span className={"text-xs"}>{"Olympics"}</span></div>
                </div>
                <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign3")}>
                  <img src={hardcodedPic3/* data?.image */} alt={obj_top?.name}/>
                  <div><span className={"text-xs"}>{"Absolut Perfection"}</span></div>
                </div>
                <div onClick={() => globals.set("dropdown_selection_campaigns", "campaign4")}>
                  <img src={hardcodedPic4/* data?.image */} alt={obj_top?.name}/>
                  <div><span className={"text-xs"}>{"Baby"}</span></div>
                </div>
                {/* <div onClick={() => globals.set("dropdown_selection_campaigns", "justdontquit")}>
                  <img src={hardcodedPic5} alt={obj_top?.name}/>
                  <div><span className={"text-xs"}>{"Think Different"}</span></div>
                </div> */}

              </div>
            </div>
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 0,
          h: 320
        }}
      />}
     
      {ROTANA_DEMO_MODE 
      ? 
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Total sessions",
                "data": arr_top[1],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Unique visits",
                "data": arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg session length",
                "data": arr_top[2],
                "unit": "s"
              }
            ]}
          />
        ]}
        template={`
            0 1 2
            `}
        cellSize={{
          w: 146,
          h: 80
        }}
      />
      : 
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Paid impressions",
                "data": arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Unique reach",
                "data": arr_top[1],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Frequency",
                "data": arr_top[2],
                "unit": ""
              }
            ]}

          />,
          <Topbar 
            data={[
              {
                "name": "Avg view time",
                "data": arr_top[3],
                "unit": "s"
              }
            ]}

          />,
          <Topbar 
            data={[
              {
                "name": "Viewability",
                "data": arr_top[4],
                "unit": "%"
              }
            ]}
          />
        ]}
        template={`
            0 1 2 3 4
            `}
        cellSize={{
          w: 1,
          h: 80
        }}
      />
      }
      
      {!ROTANA_DEMO_MODE // && ready
      ?
      <Grid
        content={[
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top audiences</span>
              <span className="text-l" onClick={() => navigate("/campaigns/01")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.audiences} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top locations</span>
              <span className="text-l" onClick={() => navigate("/campaigns/02")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.locations} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top category</span>
              <span className="text-l" onClick={() => navigate("/campaigns/03")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.category} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top times</span>
              <span className="text-l" onClick={() => navigate("/campaigns/05")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.times} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top platforms</span>
              <span className="text-l" onClick={() => navigate("/campaigns/04")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.platforms} mode={3} stick={false} label={"Name"}/>
          </div>,
          <div className="minitable-wrapper-temp">
            <div className="minitable-head-temp">
              <span className="text-xs-b">Top devices</span>
              <span className="text-l" onClick={() => navigate("/campaigns/06")}>→</span>
            </div>
            <TableSort data={arr_dat_mini.devices} mode={3} stick={false} label={"Name"}/>
          </div>,
        ]}
        template={`
           !0 1 2
            3!4 5
            `}
        cellSize={{
          w: 1, 
          h: 300
        }}
      /> : ""
      }

    </Layout>
)}
