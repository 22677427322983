import {
    useRef,
    useEffect
} from "react"
  
import { 
    Insert
} from "elements"

// Styling
import "./Details.css"

// TODO: Part of _adverty.json instead [as a CDN and/or base64 asset] of hardcoded url
import hardcodedPic from "assets/_temp/campaigns/lego.png"
import tempLogo from "assets/_temp/logos/Lego.png"

export const Details = ({ data }) => {
    const details = useRef()
    useEffect(() => {
        setTimeout(() => {
            if (data?.name) {
                details.current.classList.add("details--show")
            }
        }, 0)
    }, [data])

    return (
        <div ref={details} className={"details"}>
            <img src={hardcodedPic/* data?.image */} alt={data?.name}/>
            <div className={"details-textbox"}>
                {/* <div className={"text-xxxl-b"}>{data?.brand + ": " + data?.name}</div> */}
                <div className={"text-xxxl-b"}>{data?.name}</div>
                <div className="details-dyn-row details-dyn-row-up text-s-b">
                    <div className={"details-dyn-row-divisor"}>{data?.period}</div>
                    <div className={"disabled text-s-bb"}>{data?.gender?.[0] + " %"}</div>
                    <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-f"}>F</div>
                    <div className={"disabled text-xs details-dyn-row-gender details-dyn-row-gender-m"}>M</div>
                    <div className={"disabled text-s-bb"}>{data?.gender?.[1] + " %"}</div>
                </div>
                <div className="details-dyn-row details-dyn-row-dn text-s-b">
                    <img src={tempLogo} alt={data?.name}/>
                    <div className={"details-dyn-row-divisor"}>{data?.brand }</div>
                    <Insert what={data?.region} size={18}/>
                </div>
                <div className="disabled text-s">{data?.description}</div>
            </div>
        </div>
    )
}