import {
    useRef,
    useState,
    useEffect
} from "react"

import {
    useLocation
} from "react-router-dom"

import {
    Header,
    Sidebar
} from "components"

import "./Layout.css"

const max768 = window.matchMedia("(max-width: 768px)")
let pathnamePre

export const Layout = ({ children, header, autoscroll }) => {

    const headerTitle = header.title
    const contentView = useRef(null)

    // Desktop
    const [_, reRender] = useState(false)
    const pathname = useLocation().pathname
    const hasSub = !["/settings", "/video", "/calculator", "/help"].includes(pathname)
    const aniSub = pathname.includes("0") || pathnamePre?.includes(pathname) || !pathnamePre
    useEffect(() => {

        // if (aniSub) return  
        if (hasSub) reRender(true)
        pathnamePre = pathname

        // Autoscroll
        setTimeout(() => {
            autoscroll && contentView.current.scrollTo({ top: 0, behavior: "smooth" })// -> Non-smooth alts: contentView.current.scrollTo(0, 0) & contentView.current.scrollTop = 0
        }, 0)

    }, [header /*, autoscroll */])

    // Refresh on resize (v1 - Legacy)
    // const [sidebarReady, setSidebarReady] = useState(true)
    // useEffect(() => {
    //     setSidebarReady(false)
    //     const updateSize = () => {
    //         setSidebarReady(max768.matches)  
    //     }
    //     window.removeEventListener("resize", updateSize)
    //     window.addEventListener("resize", updateSize)
    //     // window.addEventListener("resize", updateSize, {once: true}) 
    // }, [mobile])

    // Refresh on resize (v2 - More efficient)
    const [sidebarReady, setSidebarReady] = useState(null)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 && sidebarReady === null/* True (if mobile) by default [to prevent flickering b/c of lagging 'matchMedia' on first load] */)
    const resizeObserver = useRef(null)

    useEffect(() => {
        resizeObserver.current = new ResizeObserver(() => {
            setIsMobile(max768.matches || window.innerWidth <= 768)
        })
        resizeObserver.current.observe(document.body)
        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect()
            }
        }
    }, [isMobile]) 

    return (
        <div className="layout">
            <div className="layout__header-container">
                <Header content={header} callback={(burgerState) => setSidebarReady(!burgerState)}/> 
            </div> 
            <div ref={contentView} className={`layout__content-container ${sidebarReady ? "layout__content-container--mute" : ""}`}>  
                {children}
            </div>
            <div className={`layout__sidebar-container 
                ${hasSub && aniSub ? "layout__sidebar-container--more" : ""}          
                ${"layout__sidebar-container--" + (sidebarReady ? "show" : "kill")} 
            `}>
                <Sidebar section={headerTitle} mobile={isMobile} />
            </div>
        </div>
    )
}