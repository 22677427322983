// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Timing-wide styling
import "./Timing.css"

// Timing assembly
export const Timing = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const [group, period] = globals?.context?.selected || ["_", "am"]
  //const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  //const period = globals.get("temp_selected") || "am"
  const period = globals?.context?.temp_selected || "am"

  // const group = globals?.context?.checkboxed || "_"

  
  // ...
  const periodShift = period === "am" ? 0 : 12

  console.log("WINDOW", window.location.search)

  // Dummy data loader (shared with table)
  // const arr_all = {
  //   "_": [
  //     [2, 2, 2, 2, 2, 2, 3, 27],
  //     [3, 3, 3, 3, 3, 3, 2, 13],
  //     [4, 4, 4, 4, 3, 5, 3, 25],
  //     [2, 2, 1, 2, 2, 2, 2, 31],
  //     [2, 2, 4, 1, 1, 2, 2, 54],
  //     [3, 3, 3, 3, 3, 4, 4, 96],
  //     [2, 4, 4, 2, 4, 2, 2, 31],
  //     [2, 2, 2, 2, 4, 4, 4, 43],
  //     [1, 2, 4, 2, 4, 2, 3, 31],
  //     [2, 4, 2, 4, 4, 2, 3, 33],
  //     [2, 4, 1, 2, 4, 4, 4, 66],
  //     [2, 2, 4, 2, 2, 2, 2, 31],
  //     [2, 2, 4, 2, 2, 2, 2, 27],
  //     [3, 3, 3, 3, 3, 4, 2, 13],
  //     [1, 4, 4, 2, 4, 4, 4, 25],
  //     [3, 4, 2, 5, 2, 2, 2, 31],
  //     [3, 4, 2, 5, 2, 2, 2, 12],
  //     [3, 4, 2, 4, 4, 2, 2, 31],
  //     [2, 2, 4, 2, 2, 2, 2, 21],
  //     [2, 2, 5, 2, 2, 2, 2, 31],
  //     [2, 2, 5, 2, 2, 2, 2, 78],
  //     [5, 4, 4, 2, 4, 4, 2, 39],
  //     [3, 4, 2, 4, 4, 2, 2, 13],
  //     [3, 3, 4, 4, 3, 2, 4, 11]
  //   ],
  //   "high": [
  //     [4, 1, 1, 1, 4, 4, 4, 10],
  //     [4, 2, 2, 4, 2, 3, 4, 43],
  //     [5, 2, 4, 4, 4, 3, 3, 50],
  //     [3, 4, 3, 4, 4, 2, 2, 10],
  //     [2, 4, 1, 3, 3, 1, 3, 14],
  //     [1, 3, 1, 2, 3, 3, 4, 40],
  //     [2, 4, 1, 4, 2, 5, 3, 20],
  //     [5, 3, 3, 2, 4, 4, 1, 20],
  //     [5, 5, 4, 2, 4, 5, 5, 30],
  //     [2, 2, 5, 4, 3, 5, 3, 32],
  //     [4, 2, 5, 3, 1, 4, 2, 18],
  //     [4, 5, 3, 4, 4, 4, 5, 47],
  //     [5, 2, 4, 2, 2, 4, 4, 40],
  //     [2, 1, 1, 3, 1, 5, 1, 50],
  //     [5, 4, 1, 3, 5, 3, 5, 31],
  //     [1, 4, 5, 5, 5, 2, 1, 20],
  //     [1, 3, 2, 4, 1, 2, 4, 50],
  //     [1, 3, 5, 2, 1, 2, 3, 30],
  //     [5, 1, 2, 4, 3, 2, 3, 26],
  //     [3, 4, 4, 1, 1, 5, 5, 10],
  //     [1, 1, 4, 3, 2, 5, 2, 50],
  //     [3, 2, 2, 3, 4, 4, 3, 30],
  //     [5, 5, 2, 1, 1, 4, 3, 55],
  //     [1, 1, 2, 3, 2, 2, 3, 10]
  //   ],
  //   "medium": [
  //     [2, 3, 2, 3, 2, 1, 2, 25],
  //     [3, 2, 5, 1, 5, 1, 1, 20],
  //     [4, 5, 4, 5, 4, 3, 2, 51],
  //     [3, 2, 1, 5, 5, 4, 3, 20],
  //     [5, 4, 2, 1, 4, 3, 2, 40],
  //     [5, 5, 4, 1, 5, 1, 2, 50],
  //     [1, 3, 4, 4, 3, 5, 1, 20],
  //     [2, 3, 2, 1, 1, 3, 4, 24],
  //     [1, 5, 4, 5, 3, 4, 2, 10],
  //     [3, 2, 2, 5, 3, 4, 4, 50],
  //     [4, 2, 5, 4, 1, 4, 5, 13],
  //     [3, 2, 2, 1, 3, 3, 2, 50],
  //     [1, 4, 2, 5, 3, 4, 2, 19],
  //     [2, 4, 3, 1, 4, 2, 1, 10],
  //     [4, 2, 5, 4, 5, 2, 4, 50],
  //     [4, 5, 1, 2, 1, 3, 1, 52],
  //     [5, 4, 2, 4, 3, 3, 5, 30],
  //     [2, 3, 2, 4, 4, 3, 4, 30],
  //     [4, 1, 3, 1, 4, 4, 4, 46],
  //     [5, 1, 3, 1, 1, 1, 2, 40],
  //     [1, 2, 3, 1, 4, 4, 5, 50],
  //     [3, 2, 5, 3, 1, 4, 5, 20],
  //     [5, 2, 2, 2, 2, 2, 5, 37],
  //     [1, 4, 1, 4, 2, 5, 3, 38]
  //   ],
  //   "low": [
  //     [2, 3, 5, 5, 4, 5, 2, 46],
  //     [4, 2, 1, 1, 3, 5, 2, 40],
  //     [2, 3, 5, 4, 3, 1, 5, 10],
  //     [2, 2, 5, 1, 4, 3, 4, 55],
  //     [1, 2, 1, 4, 2, 2, 4, 52],
  //     [5, 5, 2, 1, 2, 2, 3, 30],
  //     [2, 4, 5, 4, 3, 5, 4, 29],
  //     [5, 2, 2, 2, 3, 5, 1, 40],
  //     [3, 3, 2, 2, 1, 5, 2, 20],
  //     [4, 3, 4, 5, 5, 4, 3, 10],
  //     [3, 1, 2, 3, 4, 4, 4, 10],
  //     [2, 4, 1, 2, 1, 2, 5, 30],
  //     [1, 1, 3, 2, 2, 4, 5, 41],
  //     [2, 3, 1, 5, 4, 3, 3, 30],
  //     [5, 1, 5, 4, 3, 5, 5, 28],
  //     [4, 3, 2, 4, 3, 4, 5, 30],
  //     [5, 2, 2, 5, 1, 2, 4, 10],
  //     [3, 3, 2, 3, 4, 1, 1, 37],
  //     [1, 3, 2, 5, 4, 4, 3, 53],
  //     [2, 5, 4, 3, 2, 4, 4, 40],
  //     [4, 1, 3, 4, 2, 1, 2, 30],
  //     [4, 1, 4, 5, 3, 1, 3, 44],
  //     [5, 2, 5, 5, 4, 5, 4, 11],
  //     [5, 4, 1, 4, 4, 3, 1, 30]
  //   ]
  // }[group].slice(0 + periodShift, 12 + periodShift)

  // const temp_timing = [
  //   // --- Head
  //   ["Time", 0, 0, 0, 0, 0, 0, 0, 0],

  //   // --- Body (page 1)
  //   ["1am", 35, 61930, 4.74, 5.10, 4.59, 5.38, 12.63, 35.63],
  //   ["2am", 34, 53728, 4.11, 4.70, 3.53, 4.60, 12.99, 34.88],
  //   ["3am", 34, 47418, 3.63, 4.14, 2.97, 3.93, 13.35, 35.97],
  //   ["4am", 36, 48499, 3.71, 3.96, 2.70, 3.45, 13.21, 38.88],
  //   ["5am", 38, 47762, 3.65, 3.42, 3.21, 3.46, 13.00, 43.10],
  //   ["6am", 37, 42463, 3.25, 2.94, 3.70, 3.39, 13.19, 43.76],
  //   ["7am", 38, 44720, 3.42, 3.03, 3.15, 3.10, 13.07, 44.91],
  //   ["8am", 38, 46033, 3.52, 3.18, 3.42, 3.25, 13.11, 44.28],
  //   ["9am", 37, 42785, 3.27, 3.07, 3.54, 3.32, 12.70, 44.70],
  //   ["10am", 37, 50687, 3.88, 3.68, 3.28, 3.05, 12.39, 44.99],
  //   ["11am", 38, 59258, 4.53, 4.07, 3.91, 3.59, 12.27, 44.28],
  //   ["12am", 38, 61784, 4.73, 4.31, 4.57, 4.28, 12.31, 44.42],

  //   // --- Body (page 2)
  //   ["1pm", 37, 62955, 4.82, 4.67, 4.71, 4.45, 12.78, 44.30],
  //   ["2pm", 37, 69989, 5.35, 5.15, 4.62, 4.51, 12.75, 43.99],
  //   ["3pm", 38, 71542, 5.47, 4.96, 5.21, 5.00, 12.76, 44.41],
  //   ["4pm", 37, 71251, 5.45, 5.01, 5.51, 5.15, 12.63, 42.85],
  //   ["5pm", 38, 68942, 5.27, 4.46, 5.53, 5.09, 12.36, 43.64],
  //   ["6pm", 38, 64364, 4.92, 4.12, 5.60, 4.97, 12.13, 43.56],
  //   ["7pm", 38, 60721, 4.64, 4.02, 5.23, 4.66, 11.81, 42.31],
  //   ["8pm", 37, 59338, 4.54, 3.91, 4.78, 4.40, 11.79, 41.05],
  //   ["9pm", 37, 57959, 4.43, 4.07, 4.47, 4.37, 11.66, 39.77],
  //   ["10pm", 36, 57584, 4.40, 4.21, 4.07, 4.29, 11.66, 39.13],
  //   ["11pm", 36, 55666, 4.26, 4.18, 3.91, 4.25, 11.57, 38.62],
  //   ["12pm", 38, 61784, 4.73, 4.31, 4.57, 4.28, 12.31, 44.42]
  // ]// .slice(0 + periodShift, 12 + periodShift)

  // const rows = {
  //   "_": temp_timing,
  //   "high": temp_timing,
  //   "medium": temp_timing,
  //   "low": temp_timing
  // }[group]
  // const rows = temp_timing

  // Repack data for chart
  // const picks = globals.get("checkboxed") || ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]/* First access */
  /*JSON*/
  const picks = globals.get("checkboxed") || ["1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12am", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", "12pm"]/* First access */
  //const picks = globals?.context?.checkboxed || ["1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12am", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", "12pm"]/* First access */

  const jsondata = ROTANA_DEMO_MODE 
  ? 
  globals?.context?.data_json_test?.campaigns
  : 
  globals?.context?.data_json_test?.postcampaigns?.["campaign0"/*campaign*/]

  let flg_sel = []
  let arr_sel = []
  let arr_dat = []
  if (ready) {
    /*JSON*/const arr_all = ROTANA_DEMO_MODE 
      ? jsondata?.timing_chart?.[group].slice(0 + periodShift, 12 + periodShift)
      : jsondata?.timing_chart?.[group].slice(0 + periodShift, 12 + periodShift)
    for (let i = 0; i < picks?.length; i++) {
      let code = picks[i].replaceAll(/[^\d]/g, "")
      if (picks[i].includes(period)) {
        flg_sel.push(code)
        arr_sel.push(arr_all[code - 1])
      }
    }
    // arr_dat = rows
    /*JSON*/ arr_dat = [...jsondata?.timing_table?.[group]]/*?.reverse()*/
  }

  return (


    <Layout header={{
      "title": "Timing",
      "search": ""
    }}>

      {!ROTANA_DEMO_MODE && ![undefined, "allcampaigns"].includes(globals?.context?.dropdown_selection_campaigns) ? <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">
        <span onClick={() => {
          globals.set("dropdown_selection_campaigns", "allcampaigns")
        }}>Timing</span>
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.overview?.details?.name}</span>
        <span>{obj_top?.name}</span> */}
        {/* <span>{globals?.context?.data_json_test?.postcampaigns?.campaign0?.overview?.[group]?.details?.name}</span> */}
        <span>{globals?.context?.data_json_test?.postcampaigns?.campaign0?.details?.name}</span>
      </div> : <div className="_tempDummyHeader _tempDummyHeader--v2 text-l">Timing</div>}

      
      <Grid
        content={[
              
          <Chart 
          text={{
            // title: "Timing",
            // subtitle: "", 
            x_label: "Time of day",
            x_flags: flg_sel, // ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], 
            y_label: "Day of week",
            y_flags: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
          }}
          data={arr_sel}
          mode={3}

          />,
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 500// ORI: 400
        }}
        disabled={ROTANA_DEMO_MODE}
      />
      <Grid
        content={[
          <TableSort 
          
          data={arr_dat}

          stick={true}
          // undim={{"high": 4, "medium": 5, "low": 6}[group]}
          slice={{"am": [0, 12], "pm": [12, 24]}[period]} // TODO: Just list the number of items per page
          mode={ROTANA_DEMO_MODE ? 2 : 2}
          split={12}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: 700
        }}
      />

    </Layout>
)}