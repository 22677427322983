import {
  useEffect,
    useRef,
    useState
} from "react"

// Contexts
import {
    useGlobals
} from "contexts"

// Elements
import {
    Heartbeat
} from "elements"

// import {
//   ROTANA_DEMO_MODE
// } from "util/const"

// Styles
import "./Dropdown.css"

const ROTANA_DEMO_MODE_ = true // -> Manual override

const default_selection = ROTANA_DEMO_MODE_ ? undefined : "_"

export const Dropdown = ({mode}) => {
    const globals = useGlobals()
    const [opened, setOpened] = useState(0)
    const [_, reRender] = useState()

    const selection0 = useRef(default_selection)
    // const selection = useRef(default_selection)
    
    const selection1 = useRef(globals?.context?.dropdown_selection || default_selection)
    const selection2 = useRef()
    const selection3 = useRef()
    const selection4 = useRef()
    const selection5 = useRef()
    const selection6 = useRef()
    const selection7 = useRef()
    // OLD
    // const selection8 = useRef(globals?.context?.dropdown_selection_campaigns || default_selection)
    // NEW (TODO: Cleanup & normalize remaining dropdowns too)
    const selection8 = {current: globals?.context?.dropdown_selection_campaigns || default_selection}

    // const groupName1 = [
    //   // Mode 0 - Timeframes
    //   {
    //     "_": "All timeframes",
    //     "l0": "Today",
    //     "l7": "Last week",
    //     "l30": "Last month",
    //     "l365": "Last year"
    //   },
    //   // Mode 1 - Groups (group distribution) 
    //   {
    //     "_": "All groups",
    //     "high": "High engagement",
    //     "medium": "Mid engagement",
    //     "low": "Low engagement"
    //   }
    // ][(isNaN(mode) || mode > 1) ? 1 : mode][ROTANA_DEMO_MODE_ ? selection1.current : "_"]

    const groupName1 = [
      // Mode 1 - Groups (group distribution) 
      {
        "_": "All groups",
        "high": "High engagement",
        "medium": "Mid engagement",
        "low": "Low engagement"
      }
    ][0][ROTANA_DEMO_MODE_ ? selection1?.current : "_"]


    // TODO: Dynamically build from JSON data
    const groupName8 = [
      {
        "_": "Campaign",
        "allcampaigns": "All campaigns",
        "justdontquit": "Just Don't Quit",
        "justdoit": "Low engagement",
        "campaign0": "Lego: 2024 Campaign"
      }
    ][0][globals?.context?.dropdown_selection_campaigns/* TODO: Check b/c most likely redundant */ || selection8.current || "_"]


    //console.log("CAMPAIGN DROPDOWN", globals?.context?.dropdown_selection_campaigns)



    // const groupName10 = [
    //   // Mode 0 - Timeframes
    //   {
    //     "_": "All timeframes",
    //     "l0": "Today",
    //     "l7": "Last week",
    //     "l30": "Last month",
    //     "l365": "Last year"
    //   }
    // ][0][ROTANA_DEMO_MODE_ ? selection0.current : "_"]

    
    // Non-click revert to default (after the 'THIS_SECTION_NAME' breadcrumb link is clicked)
    useEffect(() => {
      if ((globals?.context?.dropdown_selection_campaigns === "allcampaigns") && mode === 8 && (selection8.current !== "allcampaigns")) {
        // ALT: globals.set("dropdown_selection_campaigns", selection8.current = "allcampaigns")
        selection8.current = "allcampaigns"
        reRender(Date.now())
      }
    }, [globals?.context?.dropdown_selection_campaigns])


    return (

    <div className={`dropdown ${ROTANA_DEMO_MODE_ && [0, 1, 8].includes(mode) ? "" : "dropdown--disabled"} ${ROTANA_DEMO_MODE_ ? "dropdown--temp-rotana" : ""} 
      
    dropdown--${(!ROTANA_DEMO_MODE_ ? (selection1.current) 
      : (mode === 0 ? "l30"/*<- HARDCODED_UNTIL_MORE_TIMEFRAMES_AVAILABLE) selection0.current*/ 
      : (mode === 8 ? selection8.current : (mode === 1 ? selection1.current : "undefined"))
      ))} text-xs-b`} onClick={() => setOpened(!opened)}>
      {/* Group distribution */}
      {
      mode === 0
      ? 
      <>
        <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>

        <span>{ROTANA_DEMO_MODE_ ? "Last month" : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {/* <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>
        <span>{ROTANA_DEMO_MODE_ ? (selection0.current ? groupName10 : "Timeframe") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div> */}
        
        {opened ? <div className={`dropdown__table dropdown__table-alt`}>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l0")}>
            Today
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l7")}>
            Last week
          </div>
          <div className="text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l30")}>
            Last month
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l365")}>
            Last year
          </div>
        </div> 
        : ""}
      </>
      : 
      mode === 1
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection1.current}`}></div>
        <span>{ROTANA_DEMO_MODE_ ? (selection1.current ? groupName1 : "Group") : "Group distribution"}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div>
        </div> 
        : ""}
      </>
      : 
      mode === 2
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection2.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection2.current ? groupName1 : "Audience") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection2.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 3
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection3.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection3.current ? groupName1 : "Location") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection3.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 4
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection4.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection4.current ? groupName1 : "Title") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection4.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 5
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection5.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection5.current ? groupName1 : "IAB") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection5.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 6
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection6.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection6.current ? groupName1 : "Device") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection6.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 7
      ?
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection7.current}`}></div> */}
        <span>{ROTANA_DEMO_MODE_ ? (selection7.current ? groupName1 : "Platform") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection7.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 8
      ? 
      <>
        {/* <div className={`dropdown__flag dropdown__flag-${selection8.current}`}></div> */}
        <span>{groupName8}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>

        {opened ? <div className={`dropdown__table dropdown__table-3rows`}>

          <div className="text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "allcampaigns")}>
            All campaigns
          </div>
          {/* <div className="text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "justdontquit")}>
            Just Don't Quit
          </div> */}
          <div className="text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "campaign0")}>
            Lego: 2024 campaign
          </div>
          <div className="dropdown__table__row--disable text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "justdoit")}>
            Just Do It
          </div>

          {/* <div className="text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "All campaigns")}>
            All campaigns
          </div>
          <div className="text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "Just Don't Quit")}>
            Just Don't Quit
          </div>
          <div className="dropdown__table__row--disable text-xs" 
            onClick={() => globals.set("dropdown_selection_campaigns", selection8.current = "Just Do It")}>
            Just Do It
          </div> */}
        </div> 
        : ""}
      </>

      : ""
      }
    </div>
    )
}